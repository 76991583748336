import React from 'react';
import { Stack } from '@mui/system';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import Box from '@mui/material/Box';
import Carousel from 'react-material-ui-carousel'

// theme stuff
import { ThemeProvider } from '@mui/material/styles';
import theme from './common/theme';

// redux stuff
import { useDispatch } from 'react-redux';
import { addUserTrackingData } from '../store/userTrackingSlice';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

function UserGuide(props) {

    const dispatch = useDispatch(); 
  
    const handleClose = () => {
        props.setOpenUserGuideDialog(false);
        // user tracking
        dispatch(addUserTrackingData({eventType: "closeUserGuide", eventDetail: {}}));
    };

    var items = [
        {
            id: 0,
            title: "SenseMate’s purpose",
        },
        {
            id: 1,
            title: "Lay of the land",
        },
        {
            id: 2,
            title: "Coding process",
        },
        {
            id: 3,
            title: "SenseMate’s charm",
        },
        {
            id: 4,
            title: "Interacting with SenseMate’s recommendations",
        },
        // {
        //     id: 5,
        //     title: "Mini challenges",
        // },
    ]

    return (
        <ThemeProvider theme={theme}>
            <Dialog
                // fullScreen
                fullWidth={true}
                maxWidth="xl"
                open={props.openUserGuideDialog}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                {/* <AppBar sx={{ position: 'fixed' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            About SenseMate
                        </Typography>
                    </Toolbar>
                </AppBar> */}
                <Box sx={{ flexGrow: 1, padding: 5 }}>
                    <Carousel
                        navButtonsAlwaysVisible={true}
                        autoPlay={false}
                        animation="fade"
                        fullHeightHover={false}
                        // duration={1}
                        // indicatorContainerProps={{
                        //     style: {
                        //       zIndex: 1,
                        //       marginTop: "-10px",
                        //       position: "relative"
                        //     }
                        // }}
                    >
                        {
                            items.map( (item, i) => <Item key={i} item={item} /> )
                        }
                    </Carousel>
                </Box>
            </Dialog>        
        </ThemeProvider>
    )
}

function Item(props)
{
    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ flexGrow: 1, 
                // height: '100vh'
            }}>
                <Stack
                    direction="column"
                    spacing={2}
                    sx={{marginLeft: 10, marginRight: 12}}
                >
                    <Typography variant="h5">
                        {props.item.title}
                    </Typography>
                    {/* Manually add in the content based on the ID */}
                    {props.item.id === 0 &&
                        <>
                        <Typography sx={{fontWeight:'bold'}}>
                            THE PROBLEM
                        </Typography>
                        <Typography>
                            Many community-centered organizations want to engage in conversations 
                            with their constituents to improve but lack the support they need to 
                            analyze the data they gather. Most of the time, these organizations 
                            either outsource the analysis, which is expensive, conduct a superficial 
                            reading of the data, or let the data collect dust and never uplift the 
                            stories people shared.
                        </Typography>
                        <Typography sx={{fontWeight:'bold'}}>
                            THE SOLUTION
                        </Typography>
                        <Typography>
                            We aim to create accessible entry points, like <b>SenseMate</b>, into the analysis 
                            process (also known as sensemaking). SenseMate helps non-expert sensemakers 
                            conduct qualitative coding, or apply a codebook (i.e. list of themes) to 
                            a dataset. 
                            <b> SenseMate enhances qualitative coding through machine learning 
                            and human-centered design methods. This website is a sneak peek of what 
                            SenseMate is capable of.</b>
                        </Typography>
                        </>
                    }
                    {props.item.id === 1 &&
                        <>
                        <Typography>
                            This demo contains stories from the 
                            <a target="_blank" rel="noopener noreferrer" href="https://portal.realtalkforchange.org/"> Real Talk for Change project</a>. 
                            Qualitative coding involves reading the stories and assigning them to 
                            themes from the codebook. 
                        </Typography>
                        <Typography>
                            Here is what the platform looks like after clicking <b style={{color:'#1976d2'}}>"START SENSEMATE"</b> or <b style={{color:'#1976d2'}}>"SAMPLE SENSEMATE"</b>:
                        </Typography>
                        <img alt="" src={require("../images/rec_overall_platform.PNG")} style={{maxWidth: "100%"}}></img>
                        </>
                    }
                    {props.item.id === 2 &&
                        <>
                        <Typography>
                            The community stories are displayed as a list on the left, and the codebook (i.e. list of themes) is shown on the right. 
                        </Typography>
                        <Typography>
                            To label (or un-label) a story with themes, just click anywhere within a story to select it and then check (or un-check) themes in the codebook.
                        </Typography>
                        <img alt="" src={require("../images/rec_select_story3.png")} style={{maxWidth: "100%"}}></img>
                        </>
                    }
                    {props.item.id === 3 &&
                        <>
                        <Typography>
                            Typically people have to code hundreds of stories, which can quickly get time-consuming 
                            and tedious. That’s where SenseMate’s <b>theme recommendations</b> come in handy! 
                            A machine-learning model generated these recommendations. 
                            Click on the <b style={{color:'#1976d2'}}>"Expand SenseMate’s Recommendations"</b> buttons 
                            to view a story’s recommendations. 
                        </Typography>
                        <img alt="" src={require("../images/rec_example2.png")} style={{maxWidth: "100%"}}></img>
                        {/* <br/> */}
                        </>
                    }
                    {props.item.id === 4 &&
                        <>
                            <Typography>
                                You can interact with a recommendation in several ways:
                            </Typography>
                            <ul>
                                <li>
                                    <Typography>Accept it</Typography>
                                </li>
                                <li>
                                    <Typography>Reject it</Typography>
                                </li>
                                <li>
                                    <Typography>Mark it as unsure</Typography>
                                </li>
                                <li>
                                    <Typography>View a reason why it was recommended</Typography>
                                </li>
                                <li>
                                    <Typography>Give feedback on SenseMate’s reason</Typography>
                                </li> 
                            </ul>
                            <Typography>
                                SenseMate went through 3 design iterations and 13 user testing sessions to make 
                                it as user-friendly as possible. As a result, we won’t explain how to use all the 
                                features, but let you naturally discover them as you attempt qualitative coding 
                                on your own!
                            </Typography>
                            <Typography>
                                Please share your feedback via the <b style={{color:'#1976d2'}}>"SUBMIT FEEDBACK"</b> button in the upper right corner of the platform.
                            </Typography>
                        </>
                    }
                    {/* {props.item.id === 5 &&
                        <>
                        <Typography>
                            <b style={{color:'#9c27b0'}}>Challenge # 1:</b> Label at least 3 stories with themes 
                        </Typography>
                        <Typography>
                            <b style={{color:'#9c27b0'}}>Challenge # 2:</b> See what happens when you accept, reject, and mark a recommendation as unsure 
                        </Typography>
                        <Typography>
                            <b style={{color:'#9c27b0'}}>Challenge # 3:</b> View at least 2 of SenseMate’s reasons 
                        </Typography>
                        <Typography>
                            <b style={{color:'#9c27b0'}}>Challenge # 4:</b> There are two ways of giving feedback on SenseMate’s reasons: 
                            (1) highlighting words to include or exclude from SenseMate’s reason and (2) answering quick 
                            yes-no questions after rejecting a recommendation. Find and test out both ways of giving feedback!
                        </Typography>
                        </>
                    } */}
                </Stack>
            </Box>
        </ThemeProvider>
    )
}

export default UserGuide;