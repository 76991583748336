import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { Stack } from '@mui/system';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';
import theme from './common/theme';

// Redux things
import { useSelector, useDispatch } from 'react-redux';
import { addSnippet, addRec, setState } from '../store/userAnswerSlice';
import { addUserTrackingData } from '../store/userTrackingSlice';

// other components
import Snippets from './Snippets';
import Codebook from './Codebook';
// import OldUserGuide from './OldUserGuide';
import UserGuide from './UserGuide';

// routing 
import { useNavigate } from "react-router-dom";

// icons
import HelpIcon from '@mui/icons-material/Help';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
// import { Navigate } from "react-router-dom";

import background from '../images/welcome_page_background.svg'

// data
import snippetData from '../data/snippetData.json';
import demoSnippetData from '../data/demoSnippetData.json';
import recData from '../data/recData.json';

function Platform(props) {
    // props include expCondition, userCode, isDemo

    // // Redirect to homepage after user refreshes and userCode is no longer available in state
    // if (props.userCode === "") {
    //     return <Navigate to="/" />
    // }

    // // TODO: uncomment the following code to prevent user from refreshing the page
    // // watch for whenever user reloads page
    // useEffect(() => {
    //     window.addEventListener("beforeunload", handleBeforeUnload);
    //     return () => {
    //       window.removeEventListener("beforeunload", handleBeforeUnload);
    //     };
    //   }, []);

    // const handleBeforeUnload = (e) => {
    //     e.preventDefault();
    //     const message =
    //         "Are you sure you want to refresh? If so, your data may be lost";
    //     e.returnValue = message;
    //     return message;
    // };

    // get the userAnswer and userTracking data from the Redux store
    // const userAnswer = useSelector(state => state.userAnswer);
    // const userTracking = useSelector(state => state.userTracking);

    // get the snippet_to_codes data from the Redux store
    // const snippet_to_codes = useSelector((state) => state.userAnswer.snippet_to_codes);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    // empty jsons for snippets and recs
    const [snippetJson, setSnippetJson] = useState({});
    const [recJson, setRecJson] = useState({});

    // Read the snippets from the data and update the Redux store
    useEffect(() => {
        if (props.isDemo) {
            // save the snippet data to the state
            setSnippetJson(demoSnippetData);
            // go through each snippet and add it to the store
            demoSnippetData["snippets"].forEach((snippet) => {
                dispatch(addSnippet(snippet["snippet_id"]));
            });
        } else {
            // save the snippet data to the state
            setSnippetJson(snippetData);
            // go through each snippet and add it to the store
            snippetData["snippets"].forEach((snippet) => {
                dispatch(addSnippet(snippet["snippet_id"]));
            });
        }
        
        // save the rec data to the state
        setRecJson(recData);
        // go through each rec and add it to the store
        recData["recs"].forEach((rec) => {
            dispatch(addRec(rec["rec_id"]));
        });

        // // check if the user already has answers saved
        // fetch(`/api/readAnswerData?uuid=${props.userCode}`).then(response => response.json()).then(data => {
        //     // console.log(data);
        //     // console.log(data.userAnswer !== undefined);
        //     if ((data !== undefined) && (data.userAnswer !== undefined) && (data.userAnswer.shownSnippets.length > 0)) {
        //         // set the entire userAnswer state to the data that was read from S3
        //         dispatch(setState(data.userAnswer));
        //     }
        //     else {
        //         // user doesn't have answers saved, so go through each snippet and add it to the store
        //         // code to test submit work
        //         // testSnippetData["snippets"].slice(0, 2).forEach((snippet) => {
        //         //     dispatch(addSnippet(snippet["snippet_id"]));
        //         // });
        //         snippetData["snippets"].forEach((snippet) => {
        //             dispatch(addSnippet(snippet["snippet_id"]));
        //         });
        //         // go through each rec and add it to the store
        //         recData["recs"].forEach((rec) => {
        //             dispatch(addRec(rec["rec_id"]));
        //         });
        //     }
        // });
    // }, [props.userCode]);
    }, [props]);

    // get height of app bar
    const [height, setHeight] = useState(0);
    const ref = useRef(null);

    useEffect(() => {
        setHeight(ref.current.clientHeight);
    }, [])

    // state variable to keep track of which snippet is selected
    const [selectedSnippet, setSelectedSnippet] = useState(undefined);

    // variables to determine whether a dialog should be full screen
    const defaultTheme = useTheme();
    const fullScreen = useMediaQuery(defaultTheme.breakpoints.down('md'));

    // state variable to keep track of which dialog window should be displayed when user tries to submit work
    const [openSubmitErrorDialog, setOpenSubmitErrorDialog] = useState(false);
    const [openSubmitSuccessDialog, setOpenSubmitSuccessDialog] = useState(false);

    // function to make sure all snippets have been assigned to at least one code
    // const checkAllSnippetsCoded = () => {
    //     // go through each key in snippet_to_codes dictionary
    //     for (const snippet_id in snippet_to_codes) {
    //         // if the snippet has not been assigned to any codes, return false
    //         if (snippet_to_codes[snippet_id].length === 0) {
    //             return false;
    //         }
    //     }
    //     // if all snippets have been assigned to at least one code, return true
    //     return true;
    // }

    // // function called when the submit button is clicked
    // const handleClickSubmit = () => {
    //     // save data to S3
    //     // console.log("saving data to S3");
    //     fetch(`/api/writeTrackingData`, {
    //         method: 'POST',
    //         headers: {
    //         Accept: "application/json",
    //         "Content-Type": "application/json",
    //         },
    //         body: JSON.stringify({
    //         userTracking: userTracking,
    //         uuid: props.userCode,
    //         expCondition: props.expCondition,
    //         }),
    //     }).then(() => {
    //         fetch(`/api/writeAnswerData`, {
    //         method: 'POST',
    //         headers: {
    //             Accept: "application/json",
    //             "Content-Type": "application/json",
    //         },
    //         body: JSON.stringify({
    //             userAnswer: userAnswer,
    //             uuid: props.userCode,
    //             expCondition: props.expCondition,
    //         }),
    //         });
    //     });
    //     // first check if all snippets have been assigned to at least one code
    //     if (checkAllSnippetsCoded()) {
    //         // console.log("Done");
    //         setOpenSubmitSuccessDialog(true);
    //         // user tracking
    //         dispatch(addUserTrackingData({eventType: "submitCompleteTask", eventDetail: {}}));
    //     } else {
    //         // console.log("Not done");
    //         setOpenSubmitErrorDialog(true);
    //         // user tracking
    //         dispatch(addUserTrackingData({eventType: "submitIncompleteTask", eventDetail: {}}));
    //     }
    // }

    // state variable to keep tack of whether the user guide dialog should be open
    const [openUserGuideDialog, setOpenUserGuideDialog] = useState(false);

    const handleClickOpenUserGuideDialog = () => {
        setOpenUserGuideDialog(true);
        // user tracking
        dispatch(addUserTrackingData({eventType: "openUserGuide", eventDetail: {}}));
    };


    return (
        <ThemeProvider theme={theme}>
        {/* Show an error message if user is using a small device */}
        {fullScreen

        ?

            <Stack 
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={3}
                sx={{ height: '100vh', width: '100vw', bgcolor: '#7ACCEF'}}
                // style={{backgroundImage: `url(${background})`, backgroundSize: 'cover'}}
            >
                <Typography variant="h2">
                    SenseMate
                </Typography>
                <Typography variant="h4" align="center" sx={{width:'90%'}}>
                    Qualitative coding enhanced.
                </Typography>
                <Typography variant="h5" align="center" sx={{width:'90%'}}>
                    Currently, SenseMate is not available on mobile devices. Please use a laptop or desktop computer.  
                </Typography>
            </Stack>

        :
            <Box sx={{ flexGrow: 1, overflowY: "hidden", maxHeight: '100vh'}}>
                {/* Add the top header bar */}
                <AppBar position="fixed" ref={ref}>
                {/* <AppBar position="static"> */}
                    <Toolbar>
                    <Stack 
                        direction="row"
                        sx={{flexGrow: 1 }}
                        alignItems="center"
                        justifyContent="flex-start"
                    >
                        <Typography variant="h4" 
                            sx={{
                                '&:hover': {
                                    cursor: 'pointer',
                                    backgroundColor: '#1976d2',  
                                },
                                }}
                            onClick={() => {navigate(`/`);}}
                        >
                            SenseMate
                        </Typography>
                    </Stack>
                    <Stack
                        direction="row"
                        spacing={2}
                    >
                        <Button color="inherit" startIcon={<HelpIcon />}
                            onClick={handleClickOpenUserGuideDialog}
                        >
                            About SenseMate
                        </Button>
                        <Button color="inherit" startIcon={<AssignmentTurnedInIcon />}
                            target="_blank" href="https://forms.gle/aZAoa4hYQ5qS8epZ9"
                        >
                            Submit Feedback
                        </Button>
                    </Stack>
                    </Toolbar>
                </AppBar>
                {/* Add the bottom grid */}
                <Grid container 
                    spacing={1}
                    alignItems="flex-start" 
                    direction="row" 
                    justifyContent="space-between" 
                    sx={{mt:`${height}px`}}
                >
                    {/* Community stories section */}
                    <Grid item xs={10} md={9} lg={8} sx={{bgcolor:'white', overflowY: "scroll", maxHeight: '90vh'}}>
                        <Stack
                            direction="column"
                            spacing={1}
                            sx={{ml:1, mr:1, paddingTop: 1}}
                        >
                            <Typography variant="h5" sx={{paddingBottom: 1}}>
                                Community Stories
                            </Typography>
                            {/* Testing out snippets */}
                            <Snippets snippetJson={snippetJson} recJson={recJson} 
                                selectedSnippet={selectedSnippet} setSelectedSnippet={setSelectedSnippet}
                                expCondition={props.expCondition}
                            />
                        </Stack>
                    </Grid>
                    {/* <Divider orientation="vertical" flexItem sx={{ mr: "-1px"}} /> */}
                    {/* Codebook section */}
                    <Grid item xs={2} md={3} lg={4} sx={{overflowY: "scroll", maxHeight: '90vh'}}>
                        <Codebook selectedSnippet={selectedSnippet} 
                        // snippetJson={snippetJson} 
                        recJson={recJson} 
                        />
                    </Grid>
                </Grid>
                {/* Add the alert dialogs when user clicks on the submit work button*/}
                <Dialog
                    open={openSubmitErrorDialog}
                    onClose={() => setOpenSubmitErrorDialog(false)}
                    fullScreen={fullScreen}
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description" sx={{fontSize: 18}}>
                            Before submitting your work and completing this task, please make sure to label every 
                            story with at least one theme.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpenSubmitErrorDialog(false)}>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={openSubmitSuccessDialog}
                    onClose={() => setOpenSubmitSuccessDialog(false)}
                    fullScreen={fullScreen}
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Next steps after completing this task"}
                    </DialogTitle>
                    <DialogContent>
                        <Stack
                            direction="column"
                            spacing={2}
                            alignItems="center"
                            justifyContent={"center"}
                        >
                            <DialogContentText id="alert-dialog-description" sx={{fontSize: 18}}>
                                Great job on completing this task! To get the completion code, please click on the survey link below and answer a few wrap-up questions.
                            </DialogContentText>
                            {/* <Button variant="bigButtons" size="large" target="_blank" 
                                href={`https://mit.co1.qualtrics.com/jfe/form/SV_cUrShxRqooADNPg?senseMateUserCode=${props.userCode}&expCondition=${props.expCondition}`}
                            >
                                    Click here to open the survey
                            </Button> */}
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpenSubmitSuccessDialog(false)}>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
                {/* User guide dialog */}
                {/* <OldUserGuide openUserGuideDialog={openUserGuideDialog} 
                    setOpenUserGuideDialog={setOpenUserGuideDialog}
                    expCondition={props.expCondition}
                /> */}
                <UserGuide 
                    openUserGuideDialog={openUserGuideDialog} 
                    setOpenUserGuideDialog={setOpenUserGuideDialog}
                />
            </Box>

        }
        
        </ThemeProvider>
    )
}

export default Platform;