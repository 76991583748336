import * as React from 'react';
import { useState, useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import theme from './common/theme';
import { Stack } from '@mui/system';
import Button from '@mui/material/Button';

// redux stuff
import { useDispatch, useSelector } from 'react-redux'
import { removeUnsureCode, editRecAction, setHighlightingInterfaceState, editNewTheme } from '../store/userAnswerSlice';
import { addUserTrackingData } from '../store/userTrackingSlice';

// icons
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import FavoriteIcon from '@mui/icons-material/Favorite';
import EditIcon from '@mui/icons-material/Edit';

// other components
import Tag from './common/Tag';

function SnippetTag(props) {
    // props include code, tagType, snippet_id, shownRec, setShownRec, recId, expCondition

    // TEMP function for debugging
    // useEffect(() => {
    //     console.log("SnippetTag: code: " + props.code + ", tagType: " + props.tagType + ", recId: " + props.recId);
    // }, [props.code, props.tagType, props.recId]);

    // get the new_theme_to_feedback data from the Redux store
    const new_theme_to_feedback = useSelector(state => state.userAnswer.new_theme_to_feedback);

    // get the rec_to_feedback data from the Redux store
    const rec_to_feedback = useSelector(state => state.userAnswer.rec_to_feedback);

    // state variable to see if user gave feedback for this code 
    const [feedbackGiven, setFeedbackGiven] = useState(false);
    useEffect(() => {
        if (props.recId === -1) {
            // check new_theme_to_feedback in Redux store
            // check if there is feedback for this new theme in the Redux store
            if ((new_theme_to_feedback[props.snippet_id] !== undefined) && 
                    (new_theme_to_feedback[props.snippet_id][props.code] !== undefined)) {
                setFeedbackGiven(true);
            }
        } else {
            // check rec_to_feedback in Redux store
            // check if there is feedback for this rec in the Redux store
            if (rec_to_feedback[props.recId].length > 0) {
                setFeedbackGiven(true);
            } 
        }
    }, [props.recId, props.snippet_id, props.code, new_theme_to_feedback, rec_to_feedback]);

    const dispatch = useDispatch(); 

    // update the shownRec state variable when user clicks on a view reason button
    const handleViewReason = (rec_id) => {
        if (props.shownRec === rec_id) {
            props.setShownRec(-1);
            // user tracking
            dispatch(addUserTrackingData({eventType: "clickHideReasonFromTag", eventDetail: {"snippet_id": props.snippet_id, "rec_id": rec_id}}));
        } else {
            // props.scrollToSnippetTop();
            props.setShownRec(rec_id);
            // user tracking
            dispatch(addUserTrackingData({eventType: "clickViewReasonFromTag", eventDetail: {"snippet_id": props.snippet_id, "rec_id": rec_id}}));
        }
    };

    // making changes to the redux store when user clicks on the remove button for unsure codes
    const handleClickRemove = () => {
        // update rec action to rejected
        dispatch(editRecAction({rec_id: props.recId, rec_action: "reject"}));
        // remove unsure code from snippet_to_unsure_codes
        dispatch(removeUnsureCode({snippet_id: props.snippet_id, code_to_remove: props.code}));
        // user tracking
        dispatch(addUserTrackingData({eventType: "clickRemoveUnsureCode", eventDetail: {"snippet_id": props.snippet_id, "rec_id": props.recId}}));
    }

    // function to run when user clicks on help sensemate improve or edit feedback
    const handleClickHelpImprove = () => {
        // determine the type
        if (props.recId === -1) {
            // set the value for the new theme to be false so the banner ad doesn't appear anymore
            dispatch(editNewTheme({snippet_id: props.snippet_id, new_theme: props.code}));
            // update highlighting_interface_state for a new theme
            dispatch(setHighlightingInterfaceState({"snippet_id": props.snippet_id, "type": "new", "code": props.code}));
        } else {
            // update highlighting_interface_state for an existing rec
            dispatch(setHighlightingInterfaceState({"snippet_id": props.snippet_id, "type": "rec", "rec_id": props.recId}));
        }
        // user tracking
        if (feedbackGiven) {
            dispatch(addUserTrackingData({eventType: "clickEditFeedbackFromTag", eventDetail: {"snippet_id": props.snippet_id, "rec_id": props.recId, "code": props.code}}));
        } else {
            dispatch(addUserTrackingData({eventType: "clickHelpImproveFromTag", eventDetail: {"snippet_id": props.snippet_id, "rec_id": props.recId, "code": props.code}}));
        }
    }

    return (
        <ThemeProvider theme={theme}>
            {
                (() => {
                    // If the experiment condition is control then display normal tags
                    if (props.expCondition === "control") {
                        return (
                            <Tag code={props.code} type={props.tagType}/>
                        )
                    }
                    // Deal with the tags with tagType == revisit
                    else if (props.tagType === "revisit") {
                        return (
                            <Stack
                                direction="row"
                                spacing={2}
                                // justifyContent="center"
                                alignItems="center"
                            >
                                <Tag code={props.code} type={props.tagType}/>
                                <Button variant="noButton"
                                    startIcon={<CancelIcon />}
                                    onClick={handleClickRemove}
                                    key={0}
                                >
                                    remove
                                </Button>
                            </Stack>
                        )
                    } 
                    // If code is revisit then display normal tag
                    else if (props.code === "revisit") {
                        return (
                            <Tag code={props.code} type={props.tagType}/>
                        )
                    }
                    // If recId is -1 then the code is a new theme
                    else if (props.recId === -1) {
                        // check if feedback is given for this code
                        if (feedbackGiven) {
                            return (
                                <Stack
                                    direction="row"
                                    spacing={2}
                                    // justifyContent="center"
                                    alignItems="center"
                                >
                                <Tag code={props.code} type={props.tagType}/>

                                <Button variant="sensemateVerified" disabled
                                    startIcon={<FavoriteIcon />}
                                    key={1}
                                >
                                    Thanks for your help!
                                </Button>
    
                                <Button 
                                    startIcon={<EditIcon />}
                                    onClick={handleClickHelpImprove}
                                    key={2}
                                >
                                    edit feedback
                                </Button>

                                </Stack>
                            )
                        } else {
                            return (
                                <Stack
                                    direction="row"
                                    spacing={2}
                                    // justifyContent="center"
                                    alignItems="center"
                                >
                                <Tag code={props.code} type={props.tagType}/>
    
                                <Button 
                                    startIcon={<FavoriteIcon />}
                                    onClick={handleClickHelpImprove}
                                    key={3}
                                >
                                    help SenseMate improve
                                </Button>
                                </Stack>
                            )
                        }
                    }
                    // If recId is not -1 then the code is a rec that the user approved (either explicitly or not)
                    else {
                        // if not rec_and_rationale condition then just display the SenseMate verified part
                        if (props.expCondition !== "rec_and_rationale") {
                            return (
                                <Stack
                                   direction="row"
                                   spacing={2}
                                   // justifyContent="center"
                                   alignItems="center"
                               >
                                   <Tag code={props.code} type={props.tagType}/>

                                   {/* add SenseMate verified  */}
                                   <Button variant="sensemateVerified" disabled
                                       startIcon={<CheckCircleIcon />}
                                       key={4}
                                   >
                                       SenseMate verified
                                   </Button>
                               </Stack>
                            )
                        }
                        // check if feedback is given for this code
                        else if (feedbackGiven) {
                            return (
                                <Stack
                                   direction="row"
                                   spacing={2}
                                   // justifyContent="center"
                                   alignItems="center"
                               >
                                   <Tag code={props.code} type={props.tagType}/>

                                   {/* add SenseMate verified  */}
                                   <Button variant="sensemateVerified" disabled
                                       startIcon={<CheckCircleIcon />}
                                       key={5}
                                   >
                                       SenseMate verified
                                   </Button>

                                   {/* add the edit feedback button if user gave feedback */}
                                    <Button 
                                        startIcon={<EditIcon />}
                                        onClick={handleClickHelpImprove}
                                        key={6}
                                    >
                                        edit feedback
                                    </Button>
                               </Stack>
                            )
                        } else {
                            return (
                                <Stack
                                   direction="row"
                                   spacing={2}
                                   // justifyContent="center"
                                   alignItems="center"
                               >
                                   <Tag code={props.code} type={props.tagType}/>

                                   {/* add SenseMate verified  */}
                                   <Button variant="sensemateVerified" disabled
                                       startIcon={<CheckCircleIcon />}
                                       key={7}
                                   >
                                       SenseMate verified
                                   </Button>

                                   <Button onClick={() => handleViewReason(props.recId)}
                                       startIcon={props.shownRec === props.recId ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                       key={8}
                                   >
                                       {props.shownRec === props.recId ? "hide reason" : "view reason"}
                                   </Button>
                               </Stack>
                            )
                        }
                    }
                })()
            }  
        </ThemeProvider>
        
    )
}

export default SnippetTag;