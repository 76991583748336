import * as React from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { ThemeProvider } from '@mui/material/styles';
import theme from './common/theme';
import Divider from '@mui/material/Divider';

// other components
import Code from './Code';
import { codes } from './common/constants';

function Codebook(props) {

    // passed into props: selectedSnippet, recJson

    return (
        <ThemeProvider theme={theme}>
        <Stack
            direction="column"
            spacing={2}
            sx={{width: '99%', paddingTop: 1, paddingBottom: 6}}
        >
            <Typography variant="h5" sx={{paddingBottom: 1}}>
                Codebook
            </Typography>
            <Typography variant="h6">
                Thematic codes
            </Typography>
            {(props.selectedSnippet === undefined) ?
                <Typography>
                    Select a story on the left to tag it with theme(s)
                </Typography>
            :
                <Typography>
                    De-select the story to stop tagging it
                </Typography>
            }
            <Stack spacing={2}>
                {codes.map((code) => (
                    <Code key={code} code={code} selectedSnippet={props.selectedSnippet}
                        // snippetJson={props.snippetJson} 
                        recJson={props.recJson}
                    />
                ))}
            </Stack>
            {/* Add in re-visit code */}
            <Divider sx={{borderBottomWidth: '2px', paddingTop:1}}/>
            <Typography variant="h6">
                Not sure how to tag?
            </Typography>
            <Code key={"revisit"} code={"revisit"} selectedSnippet={props.selectedSnippet}
                // snippetJson={props.snippetJson} 
                recJson={props.recJson}
            />
        </Stack>
        </ThemeProvider>
    );
}

export default Codebook;