import { configureStore } from '@reduxjs/toolkit';
import userAnswerReducer from '../store/userAnswerSlice';
import userTrackingReducer from '../store/userTrackingSlice';

export const store = configureStore({
  reducer: {
    userAnswer: userAnswerReducer,
    userTracking: userTrackingReducer,
  },
});
