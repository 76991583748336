import * as React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import theme from './common/theme';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';

// other components
import SnippetRec from './SnippetRec';
import { code_to_name } from './common/constants';

// redux stuff
import { useDispatch } from 'react-redux';
import { addUserTrackingData } from '../store/userTrackingSlice';

// tab bar code
// source: https://mui.com/material-ui/react-tabs/
// helper function for the content within each tab (i.e. the recs)
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
        {value === index && (
            <Box sx={{paddingTop: 2}}>
                <Box>
                    {children}
                </Box>
            </Box>
        )}
        </div>
    );
}
  
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};
  

function SnippetRecs(props) {
    // props are recs, shownRec, and setShownRec, snippet_id, tabValue, moveToNextTab, setTabValue, expCondition

    const dispatch = useDispatch(); 

    // keeping tack of the current tab (i.e. rec)
    // const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        // reset the view reason
        props.setShownRec(-1)
        props.setTabValue(newValue);
        // user tracking
        dispatch(addUserTrackingData({eventType: "navigateToRec", eventDetail: {"snippet_id": props.snippet_id, 
                "old_rec_id": props.recs[props.tabValue]["rec_id"], "new_rec_id": props.recs[newValue]["rec_id"]}}));
    };

    // // function to move to the next tab
    // // add a time delay so that the user can see the feedback
    // const moveToNextTab = () => {
    //     if (value < props.recs.length - 1) {
    //         setTimeout(() => {
    //             setValue(value + 1);
    //         }, 700);
    //     }
    // };

    return (
        <ThemeProvider theme={theme}>
            {/* Display the recs */}
            {props.recs.length === 1 ?
                <SnippetRec index={0} snippet_id={props.snippet_id} rec={props.recs[0]} 
                    shownRec={props.shownRec} setShownRec={props.setShownRec} 
                    scrollToSnippetTop={props.scrollToSnippetTop} moveToNextTab={props.moveToNextTab}
                    expCondition={props.expCondition}
                />
            :
                <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs
                            value={props.tabValue}
                            onChange={handleChange}
                            variant="scrollable"
                            scrollButtons="auto"
                        >
                            {props.recs.map((rec, index) => {
                                return (
                                    <Tab key={index} sx={{textTransform: 'none'}} 
                                        label={code_to_name[rec["code"]]} />
                                );
                            })}
                        </Tabs>
                    </Box>
                    {props.recs.map((rec, index) => {
                        return (
                            <TabPanel key={index} value={props.tabValue} index={index} >
                                <SnippetRec index={index} snippet_id={props.snippet_id} rec={rec} 
                                    shownRec={props.shownRec} setShownRec={props.setShownRec} 
                                    scrollToSnippetTop={props.scrollToSnippetTop} moveToNextTab={props.moveToNextTab}
                                    expCondition={props.expCondition}
                                />
                            </TabPanel>
                        );
                    })}
                </Box>
            }
        </ThemeProvider>
    )


}

export default SnippetRecs;