import * as React from 'react';
import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';
import theme from './common/theme';

// data
import codeExampleData from '../data/codeExampleData.json';
import { code_to_name } from './common/constants';

function CodeExample(props) {
    // props include openExample, setOpenExample, code
    
    // variables to determine if dialog should be full screen or not
    const defaultTheme = useTheme();
    const fullScreen = useMediaQuery(defaultTheme.breakpoints.down('md'));

    // function that handles closing the dialog
    const handleClose = () => {
        props.setOpenExample(false);
    };

    // variable to stores the code example data
    const [relevantData, setRelevantData] = useState(undefined);

    useEffect(() => {
        const data = codeExampleData[props.code];
        if (data !== undefined) {
            // console.log(data);
            setRelevantData(data);
        }
    }, [props.code]);

    return (
        <ThemeProvider theme={theme}>
            <Dialog
                fullScreen={fullScreen}
                open={props.openExample}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                maxWidth="md"
            >
                <DialogTitle id="responsive-dialog-title">
                    Example for {code_to_name[props.code]}
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ flexGrow: 1, border: `2px solid #9E9E9E`, padding:1, overflowY: "scroll", maxHeight: "200px", bgcolor:'white'}}>
                        {/* Display speaker name */}
                        {(relevantData !== undefined) &&
                            <Typography sx={{color: theme.palette.primary.main, fontWeight: 'bold', fontSize:18, paddingBottom:0.5}}>
                                {relevantData["speaker_name"]}
                            </Typography>
                        }
                        {/* Display normal text if shownRec is -1 */}
                        {(relevantData !== undefined) &&
                            <Typography 
                            >
                                {relevantData["text"]}
                            </Typography>
                        }
                    </Box>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose}>
                    Close
                </Button>
                </DialogActions>
            </Dialog>
        </ThemeProvider>
    );
}

export default CodeExample;