import * as React from 'react';
import { useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import theme from './common/theme';
import { Stack } from '@mui/system';
import Button from '@mui/material/Button';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Checkbox from '@mui/material/Checkbox';

import { code_to_color, code_to_name, code_to_definition } from './common/constants';
import CodeExample from './CodeExample';

// icons
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// redux stuff
import { useSelector, useDispatch } from 'react-redux';
import { addCode, removeCode, removeUnsureCode, editRecAction, addNewTheme, editNewTheme } from '../store/userAnswerSlice';
import { addUserTrackingData } from '../store/userTrackingSlice';

function Code(props) {
    // props include: code, selectedSnippet, recJson

    // first read in the snippet_to_codes data from the redux store
    const snippet_to_codes = useSelector(state => state.userAnswer.snippet_to_codes);

    // get the relevant recs for the snippet
    const [recs, setRecs] = useState([]);
    useEffect(() => {
        // get all the recs for a particular snippet
        if ((props.recJson !== undefined) && (props.recJson["recs"] !== undefined)) {
            const filtered_recs = props.recJson["recs"].filter((rec) => {
                if (rec["snippet_id"] === props.selectedSnippet) {
                    return rec;
                }  
            });
            // // go through the recs and update the colors
            // filtered_recs.forEach((rec) => {
            //     // replace the placeholder color with the right one
            //     const color = code_to_color[rec["code"]];
            //     rec["rationale_html"] = rec["rationale_html"].replaceAll("input_code_color", color);
            //     rec["name"] = code_to_name[rec["code"]];
            // });
            // console.log(filtered_recs);
            setRecs(filtered_recs);
        }
    }, [props.selectedSnippet, props.recJson]);

    // state variable to keep track of whether a code is selected or not
    const [checked, setChecked] = useState(false);
    
    // get the codes for the selected snippet and set the checked state variable
    useEffect(() => {
        if (props.selectedSnippet !== undefined) {
            const codes = snippet_to_codes[props.selectedSnippet];
            setChecked(codes.includes(props.code));
        }
    }, [props.selectedSnippet, snippet_to_codes]);

    const dispatch = useDispatch(); 

    const handleChange = (event) => {
        setChecked(event.target.checked);
        // deploy change to redux store
        if (event.target.checked) {
            dispatch(addCode({snippet_id: props.selectedSnippet, code: props.code}));
            // track action
            dispatch(addUserTrackingData({eventType: "selectCode", eventDetail: {"snippet_id": props.selectedSnippet, "code": props.code}}));
            // if the code is in the unsure code list of the snippet, then remove it
            dispatch(removeUnsureCode({snippet_id: props.selectedSnippet, code_to_remove: props.code}));
            // if the code is in the recs, then edit its action to be 'approve'
            const filtered_recs = recs.filter((rec) => {
                if (rec["code"] === props.code) {
                    return rec;
                }
            });
            if (filtered_recs.length > 0) {
                dispatch(editRecAction({rec_id: filtered_recs[0]["rec_id"], rec_action: "approve"}));
            } 
            // make sure the code isn't the re-visit code
            else if (props.code !== "revisit") {
                // it's a new theme so add it to snippet_to_new_themes
                dispatch(addNewTheme({snippet_id: props.selectedSnippet, new_theme: props.code}));
            }
        } else {
            dispatch(removeCode({snippet_id: props.selectedSnippet, code_to_remove: props.code}));
            // track action
            dispatch(addUserTrackingData({eventType: "deselectCode", eventDetail: {"snippet_id": props.selectedSnippet, "code": props.code}}));
            // if the code is in the recs, then edit its action to be 'reject'
            const filtered_recs = recs.filter((rec) => {
                if (rec["code"] === props.code) {
                    return rec;
                }
            });
            if (filtered_recs.length > 0) {
                dispatch(editRecAction({rec_id: filtered_recs[0]["rec_id"], rec_action: "reject"}));
            } 
            // make sure the code isn't the re-visit code
            else if (props.code !== "revisit") {
                // it's a new theme so set the showAd to false since we don't want to show the banner ad again
                dispatch(editNewTheme({snippet_id: props.selectedSnippet, new_theme: props.code}));
            }
        }
    };

    // state variable for whether accordian is expanded or not
    const [expanded, setExpanded] = useState(false);
    // set expanded when user clicked on the code
    const handleAccordionClick = () => {
        // user tracking
        if (expanded) {
            dispatch(addUserTrackingData({eventType: "collapseCode", eventDetail: {"code": props.code}}));
        } else {
            dispatch(addUserTrackingData({eventType: "expandCode", eventDetail: {"code": props.code}}));
        }
        setExpanded(!expanded);
    }

    // whether code example dialog is open or closed
    const [openExample, setOpenExample] = React.useState(false);

    // function that opens the code example dialog
    const handleClickOpenExample = () => {
        setOpenExample(true);
        // track action
        dispatch(addUserTrackingData({eventType: "clickCodeExample", eventDetail: {"code": props.code}}));
    };

    return (
        <ThemeProvider theme={theme}>
            <Stack
                direction="row"
                spacing={1}
                justifyContent="flex-start"
                alignItems="flex-start"
            >
                {(props.selectedSnippet !== undefined) &&
                    <Checkbox checked={checked} onChange={handleChange} size="large" sx={{paddingLeft:0, paddingTop:0, paddingBottom:0}}/>
                }
                <Accordion sx={{bgcolor:code_to_color[props.code], width:'100%'}} 
                    expanded={expanded} onChange={handleAccordionClick}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon sx={{color:'white'}}/>}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography sx={{color:'white'}}>{code_to_name[props.code]}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Stack
                            direction="column"
                            spacing={2}
                            justifyContent="center"
                            alignItems="flex-start"
                        >
                            <Typography sx={{color:'white'}}>{code_to_definition[props.code]}</Typography>
                            {props.code !== "revisit" &&
                                <Button variant="showExample"
                                    sx={{paddingLeft:1, paddingRight:1}}
                                    onClick={handleClickOpenExample}
                                >
                                    show example
                                </Button>
                            }
                        </Stack>
                    </AccordionDetails>
                </Accordion>
            </Stack>
            <CodeExample code={props.code} openExample={openExample} setOpenExample={setOpenExample}/>
        </ThemeProvider>
    )
}

export default Code;