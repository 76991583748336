import * as React from 'react';
import { Stack } from '@mui/system';
import { ThemeProvider } from '@mui/material/styles';
import theme from './common/theme';

// Redux stuff
import { useSelector } from 'react-redux'

// import other components
import Snippet from './Snippet';

function Snippets(props) {
    // props include the snippet and rec data and expCondition

    const shownSnippets = useSelector(state => state.userAnswer.shownSnippets);

    // useEffect(() => {
    //     console.log(shownSnippets);
    // }, [shownSnippets]);

    return (
        // iterate through all the shown snippets and display them in a stack
        <ThemeProvider theme={theme}>
        <Stack
            direction="column"
            spacing={4}
            // sx={{ width: '100%' }}
            // sx={{ml:1, mr:1}}
            sx={{paddingBottom: 6}}
            justifyContent="center"
            alignItems="center"
        >
            {shownSnippets.map((snippet_id) => (
                <Snippet key={snippet_id} id={snippet_id} 
                    snippetJson={props.snippetJson} recJson={props.recJson}
                    selectedSnippet={props.selectedSnippet} setSelectedSnippet={props.setSelectedSnippet}
                    expCondition={props.expCondition}
                />
            ))}
        </Stack>
        </ThemeProvider>
    );
}

export default Snippets;