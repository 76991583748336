import * as React from 'react';
import { useState, useEffect } from 'react';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';

// icons
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import { Typography } from '@mui/material';
import parse from 'html-react-parser';

import { code_to_color, code_to_name } from './constants';

// Different types of banners: info with plain text, 
// info with code-specific text, and info with code-specific text + buttons
// props contains the type of banner and the relevant code and handleClick functions

function BannerAd(props) {

    // const [frontText, setFrontText] = useState('');
    // const [backText, setBackText] = useState('');

    // // check if props.text contains the code and set the frontText (text before the code) and backText (text after the code) accordingly
    // // very limited! only assumes that there is one instance of the code in props.text
    // useEffect(() => {
    //     if ((props.code !== undefined) && (props.text !== undefined)) {
    //         // set the front and back text
    //         const index = props.text.indexOf(props.code);
    //         if (index !== -1) {
    //             setFrontText(props.text.substring(0, index));
    //             setBackText(props.text.substring(index + props.code.length));
    //         }
    //     } else if (props.text !== undefined) {
    //         setFrontText(props.text);
    //     }
    // }, [props.text, props.code]);

    const [text, setText] = useState("");
    // set the text state after processing props.text
    useEffect(() => {
        if (props.text !== undefined) {
            var newText = props.text;
            // if the text contains the code, replace the code with the code name and color
            if (props.text.includes("input_code_name")) {
                newText = newText.replace("input_code_name", `<b style="color:${code_to_color[props.code]}">${code_to_name[props.code]}</b>`);
            } 
            // if the text wants to bold and color another word, then update that too (view reason info banner)
            if (props.text.includes("input_code_color")) {
                newText = newText.replace("<b input_code_color>", `<b style="color:${code_to_color[props.code]}">`);
                // console.log(newText);
            }
            setText(newText);
        }
    }, [props.text, props.code]);


    return (
        <ThemeProvider theme={theme}>
        <Stack sx={{ width: '100%' }}>
            {/* Plain banner ad with only the text */}
            {props.type === "plain" &&
                [props.selected ?
                    <Alert severity="info" 
                        variant='outlined'
                        style={{backgroundColor: "white" }}
                        key={0}
                    >
                        {parse(text)}
                    </Alert>
                : 
                    <Alert severity="info" 
                        // color="info"
                        style={{backgroundColor: "#c4eafc" }}
                        key={1}
                    >
                        {parse(text)}
                    </Alert>
                ] 
            }
            {/* A special type of banner ad that has a yes and no button */}
            {props.type === "infoWithButtons" &&
                [props.selected ?
                    <Alert severity="info" 
                        variant='outlined'
                        key={2}
                        style={{backgroundColor: "white" }}
                        action={
                        <Stack
                            direction="row"
                            spacing={1}
                            justifyContent="center"
                            alignItems="center"
                        >
                            <IconButton aria-label="yes" variant="yesButton" onClick={props.handleClickYes}>
                                <Stack
                                    direction="column"
                                    spacing={0}
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <CheckCircleIcon fontSize="small"/>
                                    <Typography variant="caption">Yes</Typography>
                                </Stack>
                            </IconButton>
                            <IconButton aria-label="no" variant="noButton" onClick={props.handleClickNo}>
                                <Stack
                                    direction="column"
                                    spacing={0}
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <CancelIcon fontSize="small"/>
                                    <Typography variant="caption">No</Typography>
                                </Stack>
                            </IconButton>
                        </Stack>
                        }
                    >
                        {parse(text)}
                    </Alert>
                : 
                    <Alert key={3} severity="info" style={{backgroundColor: "#c4eafc" }}
                        action={
                        <Stack
                            direction="row"
                            spacing={1}
                            justifyContent="center"
                            alignItems="center"
                        >
                            <IconButton aria-label="yes" variant="yesButton" onClick={props.handleClickYes}>
                                <Stack
                                    direction="column"
                                    spacing={0}
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <CheckCircleIcon fontSize="small"/>
                                    <Typography variant="caption">Yes</Typography>
                                </Stack>
                            </IconButton>
                            <IconButton aria-label="no" variant="noButton" onClick={props.handleClickNo}>
                                <Stack
                                    direction="column"
                                    spacing={0}
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <CancelIcon fontSize="small"/>
                                    <Typography variant="caption">No</Typography>
                                </Stack>
                            </IconButton>
                        </Stack>
                        }
                    >
                        {parse(text)}
                    </Alert>
                ]
            }
        </Stack>
        </ThemeProvider>
    )
}

export default BannerAd;