import * as React from 'react';
import { useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import theme from './common/theme';
import { Stack } from '@mui/system';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

// redux stuff
import { useSelector, useDispatch } from 'react-redux'
import { editRecAction, addCode, removeCode, addUnsureCode, removeUnsureCode, setHighlightingInterfaceState } from '../store/userAnswerSlice';
import { addUserTrackingData } from '../store/userTrackingSlice';

// other components
import Tag from './common/Tag';
import { rec_action_to_name, code_to_name, code_to_definition } from './common/constants';
import QuickQuestions from './QuickQuestions';

// icons
import UndoIcon from '@mui/icons-material/Undo';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import HelpIcon from '@mui/icons-material/Help';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import EditIcon from '@mui/icons-material/Edit';


function SnippetRec(props) {
    // props are index, rec, shownRec, setShownRec, snippet_id, moveToNextTab, scrollToSnippetTop, expCondition

    // update the shownRec state variable when user clicks on a view reason button
    const handleViewReason = (rec_id) => {
        if (props.shownRec === rec_id) {
            props.setShownRec(-1);
            // user tracking
            dispatch(addUserTrackingData({eventType: "clickHideReasonFromRec", eventDetail: {"snippet_id": props.snippet_id, "rec_id": rec_id}}));
        } else {
            props.scrollToSnippetTop();
            props.setShownRec(rec_id);
            // user tracking
            dispatch(addUserTrackingData({eventType: "clickViewReasonFromRec", eventDetail: {"snippet_id": props.snippet_id, "rec_id": rec_id}}));
        }
    };

    // first read in the relevant rec action data from the Redux store 
    const recActions = useSelector((state) => {
        // go through each rec in redux store and filter the ones I need
        return state.userAnswer.rec_to_action;
    });

    // get the rec_to_feedback data from the Redux store
    const rec_to_feedback = useSelector(state => state.userAnswer.rec_to_feedback);

    // get the rec action for this particular rec
    const recAction = recActions[props.rec["rec_id"]];

    // state variable to see if user gave feedback for this rec 
    const [feedbackGiven, setFeedbackGiven] = useState(false);
    useEffect(() => {
        // check if there is feedback for this rec in the Redux store
        if (rec_to_feedback[props.rec["rec_id"]].length > 0) {
            setFeedbackGiven(true);
        } 
    }, [rec_to_feedback, props.rec]);

    // code to update the redux store when user clicks on a button to update the action of a rec
    const dispatch = useDispatch(); 

    // function to run when user clicks on help sensemate improve or edit feedback
    const handleClickHelpImprove = () => {
        // update highlighting_interface_state for an existing rec
        dispatch(setHighlightingInterfaceState({"snippet_id": props.snippet_id, "type": "rec", "rec_id": props.rec["rec_id"]}));
        // user tracking
        if (feedbackGiven) {
            dispatch(addUserTrackingData({eventType: "clickEditFeedbackFromRec", eventDetail: {"snippet_id": props.snippet_id, "rec_id": props.rec["rec_id"]}}));
        } else {
            dispatch(addUserTrackingData({eventType: "clickHelpImproveFromRec", eventDetail: {"snippet_id": props.snippet_id, "rec_id": props.rec["rec_id"]}}));
        }
        // scroll to the top of the snippet
        props.scrollToSnippetTop();
    } 

    // handle click approve
    const handleClickApprove = (rec_id) => {
        // update the redux store
        dispatch(editRecAction({rec_id: rec_id, rec_action: "approve"}));
        // add the code
        dispatch(addCode({snippet_id: props.snippet_id, code: props.rec["code"]}));
        // if the code is in the unsure code list of the snippet, then remove it
        dispatch(removeUnsureCode({snippet_id: props.snippet_id, code_to_remove: props.rec["code"]}));
        // user tracking
        dispatch(addUserTrackingData({eventType: "clickApproveForRec", eventDetail: {"snippet_id": props.snippet_id, "rec_id": props.rec["rec_id"]}}));
        // reset shownRec
        props.setShownRec(-1);
        // move to the next rec if there is one
        props.moveToNextTab();
    };

    // handle click reject
    const handleClickReject = (rec_id) => {
        // update the redux store
        dispatch(editRecAction({rec_id: rec_id, rec_action: "reject"})); 
        // if the rec is in the codes of the snippet, then remove it
        dispatch(removeCode({snippet_id: props.snippet_id, code_to_remove: props.rec["code"]}));
        // if the rec is in the unsure codes of the snippet, then remove it
        dispatch(removeUnsureCode({snippet_id: props.snippet_id, code_to_remove: props.rec["code"]}));
        // user tracking
        dispatch(addUserTrackingData({eventType: "clickRejectForRec", eventDetail: {"snippet_id": props.snippet_id, "rec_id": props.rec["rec_id"]}}));
        // reset shownRec
        props.setShownRec(-1);
        // if feedback given then move to the next rec
        if (feedbackGiven) {
            props.moveToNextTab();
        }
    };

    // handle click unsure
    const handleClickUnsure = (rec_id) => {
        // update the redux store
        dispatch(editRecAction({rec_id: rec_id, rec_action: "unsure"}));
        // add the code
        dispatch(addUnsureCode({snippet_id: props.snippet_id, code: props.rec["code"]}));
        // if the rec is in the codes of the snippet, then remove it
        dispatch(removeCode({snippet_id: props.snippet_id, code_to_remove: props.rec["code"]}));
        // user tracking
        dispatch(addUserTrackingData({eventType: "clickUnsureForRec", eventDetail: {"snippet_id": props.snippet_id, "rec_id": props.rec["rec_id"]}}));
        // reset shownRec
        props.setShownRec(-1);
        // move to the next rec if there is one
        props.moveToNextTab();
    };

    // handle click undo
    const handleClickUndo = (rec_id) => {   
        // if recAction is currently approve then we need to remove the code as well
        if (recAction === "approve") {
            dispatch(removeCode({snippet_id: props.snippet_id, code_to_remove: props.rec["code"]}));
        }
        // I don't have to remove the code from the unsure because it isn't possible to undo after marking a rec as unsure
        // update the redux store
        dispatch(editRecAction({rec_id: rec_id, rec_action: "none"}));
        // user tracking
        dispatch(addUserTrackingData({eventType: "clickUndoForRec", eventDetail: {"snippet_id": props.snippet_id, "rec_id": props.rec["rec_id"]}}));
    };


    return (
        <ThemeProvider theme={theme}>
            <Stack
                key={props.rec["rec_id"]}
                direction="column"
                spacing={1}
                justifyContent="center"
                // alignItems="center"
            >
                {/* Create buttons in first row */}
                <Stack
                    direction="row"
                    spacing={0.5}
                    // justifyContent="center"
                    alignItems="center"
                >

                    {/* <Tag code={props.rec["code"]} type={recAction==="none" ? "normal" : "lowerOpacity"}/> */}
                    <Tag code={props.rec["code"]} type="normal"/>

                    <Stack
                        direction="row"
                        spacing={0.5}
                        // justifyContent="center"
                        alignItems="center"
                    >

                        {(recAction === "approve") &&
                            <Tooltip title={rec_action_to_name[recAction]} placement="bottom" arrow>
                                <IconButton variant="yesButton" disableRipple={true}>
                                    <CheckCircleIcon />
                                </IconButton>
                            </Tooltip>
                        }

                        {(recAction === "reject") &&
                            <Tooltip title={rec_action_to_name[recAction]} placement="bottom" arrow>
                                <IconButton variant="noButton" disableRipple={true}>
                                    <CancelIcon />
                                </IconButton>
                            </Tooltip>
                        }

                        {(recAction === "unsure") &&
                            <Tooltip title={rec_action_to_name[recAction]} placement="bottom" arrow>
                                <IconButton variant="unsureButton" disableRipple={true}>
                                    <HelpIcon />
                                </IconButton>
                            </Tooltip>
                        }

                        {/* Add undo button if applicable */}
                        {(recAction !== "none") && (recAction !== "unsure") &&
                            <Button onClick={() => handleClickUndo(props.rec["rec_id"])} 
                                startIcon={<UndoIcon />}
                                size="small"
                                sx={{paddingLeft:1, paddingRight:1}}
                            >
                                Undo
                            </Button>
                        }
                    </Stack>

                    {(recAction === "approve") && (props.expCondition === "rec_and_rationale") &&
                        <>
                            {/* show edit feedback button if user gave feedback on rec already, else show help improve more button */}
                            {feedbackGiven ?
                                <Stack
                                    direction="row"
                                    spacing={1}
                                    alignItems="center"
                                    sx={{paddingLeft:3}}
                                >
                                    <Button 
                                        startIcon={<EditIcon />}
                                        onClick={handleClickHelpImprove}
                                        key={0}
                                    >
                                        edit feedback
                                    </Button>
                                </Stack>
                            :
                                <Stack
                                    direction="row"
                                    spacing={1}
                                    alignItems="center"
                                    sx={{paddingLeft:3}}
                                >
                                    {/* <Typography
                                        // sx={{fontWeight: "bold"}}
                                    >
                                        Thanks!
                                    </Typography> */}

                                    <Button
                                        onClick={handleClickHelpImprove}
                                        key={1}
                                    >
                                        help SenseMate improve
                                    </Button>
                                </Stack>
                            }
                        </>
                    }

                    {(recAction === "reject") && feedbackGiven && (props.expCondition === "rec_and_rationale") &&
                        // if user gave feedback then show the following
                        <Stack
                            direction="row"
                            spacing={1}
                            // justifyContent="center"
                            alignItems="center"
                            sx={{paddingLeft:3}}
                        >
                            <Typography>
                                Thanks for your help!
                            </Typography>

                            <Button 
                                startIcon={<EditIcon />}
                                onClick={handleClickHelpImprove}
                                key={2}
                            >
                                edit feedback
                            </Button>
                        </Stack>     
                    }

                    {(recAction === "unsure") &&
                    <Stack
                        direction="row"
                        spacing={2}
                        alignItems="center"
                        // sx={{paddingLeft:1}}
                    >
                        <Button variant="yesButton" onClick={() => handleClickApprove(props.rec["rec_id"])}
                            startIcon={<CheckCircleIcon />}
                            key={3}
                        >
                            Approve
                        </Button>
                        <Button variant="noButton" onClick={() => handleClickReject(props.rec["rec_id"])}
                            startIcon={<CancelIcon />}
                            key={4}
                        >
                            Reject
                        </Button>
                        {/* show edit feedback button if user gave feedback on rec already, else show view reason button */}
                        {(props.expCondition === "rec_and_rationale") &&
                            [feedbackGiven ?
                                <Button 
                                    startIcon={<EditIcon />}
                                    onClick={handleClickHelpImprove}
                                    key={5}
                                >
                                    edit feedback
                                </Button>
                            :
                                <Button onClick={() => handleViewReason(props.rec["rec_id"])}
                                    startIcon={props.shownRec === props.rec["rec_id"] ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                    key={6}
                                >
                                    {props.shownRec === props.rec["rec_id"] ? "hide SenseMate’s reason" : "view SenseMate’s reason"}
                                </Button>
                            ]
                        }
                    </Stack>
                    }

                </Stack>
                {/* Create buttons in second row */}
                {(recAction === "none") &&
                    <Stack
                        direction="column"
                        spacing={2}
                    >
                    <Stack
                        direction="row"
                        spacing={2}
                        alignItems="center"
                    >
                        <Button variant="yesButton" onClick={() => handleClickApprove(props.rec["rec_id"])}
                            startIcon={<CheckCircleIcon />}
                            key={7}
                        >
                            Approve
                        </Button>
                        <Button variant="noButton" onClick={() => handleClickReject(props.rec["rec_id"])}
                            startIcon={<CancelIcon />}
                            key={8}
                        >
                            Reject
                        </Button>
                        <Button onClick={() => handleClickUnsure(props.rec["rec_id"])}
                            startIcon={<HelpIcon />}
                            key={9}
                        >
                            Unsure
                        </Button>
                        {/* show edit feedback button if user gave feedback on rec already, else show view reason button */}
                        {(props.expCondition === "rec_and_rationale") &&
                            [feedbackGiven ?
                                <Button 
                                    startIcon={<EditIcon />}
                                    onClick={handleClickHelpImprove}
                                    key={10}
                                >
                                    edit feedback
                                </Button>
                            :
                                <Button onClick={() => handleViewReason(props.rec["rec_id"])}
                                    startIcon={props.shownRec === props.rec["rec_id"] ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                    key={11}
                                >
                                    {props.shownRec === props.rec["rec_id"] ? "hide SenseMate’s reason" : "view SenseMate’s reason"}
                                </Button>
                            ]
                        }
                    </Stack>
                    <Typography sx={{fontWeight:'bold'}}>
                            {code_to_name[props.rec["code"]]}: {code_to_definition[props.rec["code"]]}
                    </Typography>
                    </Stack>
                }
                {(recAction === "reject") && (props.expCondition === "rec_and_rationale") &&
                    [!feedbackGiven &&
                        // if user didn't give feedback then show quick questions
                        <QuickQuestions key={0} rec={props.rec} 
                            title={"Thanks for your help! Help SenseMate perform better by answering these quick questions"}
                            moveToNextTab={props.moveToNextTab}
                        />
                    ]
                }
                {(recAction === "unsure") &&
                    <Stack
                        direction="column"
                        spacing={2}
                        sx={{paddingTop:1}}
                    >
                    <Typography sx={{fontWeight:'bold'}}>
                        {code_to_name[props.rec["code"]]}: {code_to_definition[props.rec["code"]]}
                    </Typography>
                    </Stack>
                }
            </Stack>
        </ThemeProvider>
    );
}

export default SnippetRec;