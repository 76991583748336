import React from 'react';
import { useState, useEffect } from 'react';
import './App.css';
import Platform from './components/Platform';
import NoPage from './components/NoPage';
import StartPage from './components/StartPage';

// redux stuff
// import { useSelector } from 'react-redux';

// routing
import { Routes, Route } from "react-router-dom";

function App() {

  // state varaible to keep track of whether the complete or demo version is selected
  const [isDemo, setIsDemo] = useState(false);

  // // state variable to keep track of the user code
  // const [userCode, setUserCode] = useState("");

  // // state variable to keep track of what experiment condition user is in
  const [expCondition, setExpCondition] = useState("rec_and_rationale");

  // temp function for testing
  // useEffect(() => {
  //   console.log("userCode: " + userCode);
  //   console.log("expCondition: " + expCondition);
  // }, [userCode, expCondition]);

  // // create useEffect function to save the userAnswer and userTracking data in the Redux store to S3 every minute
  // const userAnswer = useSelector(state => state.userAnswer);
  // const userTracking = useSelector(state => state.userTracking);

  // // last time data was saved
  // const [lastSaveTime, setLastSaveTime] = useState(new Date());

  // useEffect(() => {

  //   if (userCode === "") {
  //     return;
  //   } 
    
  //   // check if it has been more than 30 seconds since last save
  //   const currentTime = new Date();
  //   if (currentTime - lastSaveTime < 30000) {
  //     return;
  //   }

  //   // console.log("saving data to S3");

  //   fetch(`/api/writeTrackingData`, {
  //     method: 'POST',
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify({
  //       userTracking: userTracking,
  //       uuid: userCode,
  //       expCondition: expCondition,
  //     }),
  //   }).then(() => {
  //     fetch(`/api/writeAnswerData`, {
  //       method: 'POST',
  //       headers: {
  //         Accept: "application/json",
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({
  //         userAnswer: userAnswer,
  //         uuid: userCode,
  //         expCondition: expCondition,
  //       }),
  //     });
  //   });

  //   setLastSaveTime(currentTime);
  // }, [userCode, userAnswer, userTracking, expCondition]);

  // // save data when component unmounts
  // useEffect(() => {
  //   // Save result when app gets closed
  //   return () => {
  //     if (userCode !== "") {
  //       fetch(`/api/writeTrackingData`, {
  //         method: 'POST',
  //         headers: {
  //           Accept: "application/json",
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify({
  //           userTracking: userTracking,
  //           uuid: userCode,
  //           expCondition: expCondition,
  //         }),
  //       }).then(() => {
  //         fetch(`/api/writeAnswerData`, {
  //           method: 'POST',
  //           headers: {
  //             Accept: "application/json",
  //             "Content-Type": "application/json",
  //           },
  //           body: JSON.stringify({
  //             userAnswer: userAnswer,
  //             uuid: userCode,
  //             expCondition: expCondition,
  //           }),
  //         });
  //       });
  //     }
  //   }
  // }, []);

  // temp function for testing
  // useEffect(() => {
  //   console.log(userAnswer);
  //   console.log(userTracking);
  // }, [userAnswer, userTracking]);

  return (
    <div>
      <Routes>
        {/* <Route exact path="/" element={<StartPage setUserCode={setUserCode} setExpCondition={setExpCondition}/>} />
        <Route exact path="/platform/" element={<Platform userCode={userCode} expCondition={expCondition}/>} /> */}
        <Route exact path="/" element={<StartPage setIsDemo={setIsDemo}/>} />
        <Route exact path="/platform/" element={<Platform expCondition={expCondition} isDemo={isDemo}/>} />
        <Route path="*" element={<NoPage />} />
      </Routes>
    </div>
  );
}

export default App;
