import * as React from 'react';
import { useState } from 'react';
import AppBar from '@mui/material/AppBar';
// import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import { Stack } from '@mui/system';
import Typography from '@mui/material/Typography';
// import TextField from '@mui/material/TextField';
import { ThemeProvider } from '@mui/material/styles';
import theme from './common/theme';
import Tooltip from '@mui/material/Tooltip';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

// icons
import HelpIcon from '@mui/icons-material/Help';

// import user guide component
import UserGuide from './UserGuide';

import background from '../images/welcome_page_background.svg'

// Redux stuff
import { useDispatch } from 'react-redux';
import { addUserTrackingData, resetTrackingState } from '../store/userTrackingSlice';
import { resetAnswerState } from '../store/userAnswerSlice';

// routing 
import { useNavigate } from "react-router-dom";

function StartPage(props) {
    // props include setUserCode, setExpCondition

    const navigate = useNavigate();
    const dispatch = useDispatch();

    // figure out whether user is using a small screen
    const defaultTheme = useTheme();
    const smallScreen = useMediaQuery(defaultTheme.breakpoints.down('md'));

    // state variable to keep tack of whether the user guide dialog should be open
    const [openUserGuideDialog, setOpenUserGuideDialog] = useState(false);

    const handleClickOpenUserGuideDialog = () => {
        setOpenUserGuideDialog(true);
        // user tracking
        dispatch(addUserTrackingData({eventType: "openUserGuide", eventDetail: {}}));
    };

    // // state variable to keep track of the user code input
    // const [userCode, setUserCode] = React.useState("");

    // // state variable to keep track of whether there is an error with the user code input
    // const [userCodeError, setUserCodeError] = React.useState(false);

    // // function to remove leading and trailing whitespace from user code input
    // const trimUserCode = (userCode) => {
    //     return userCode.trim();
    // }

    const handleClick = () => {
        // set the demo state to false
        props.setIsDemo(false);

        // reset the userAnswer and userTracking data in Redux Store
        dispatch(resetAnswerState());
        dispatch(resetTrackingState());

        // directly navigate to the platform page
        navigate(`/platform/`);

        // const trimmedUserCode = trimUserCode(userCode);
        // // get the experiment condition for the userCode using flask api
        // fetch(`/api/getExpCondition?uuid=${trimmedUserCode}`).then(response => response.json()).then(data => {
        //     // console.log(data);
        //     if (data['isValid']) {
        //         setUserCodeError(false);
        //         props.setUserCode(trimmedUserCode);
        //         props.setExpCondition(data['expCondition']);
        //         navigate(`/platform/${trimmedUserCode}`);
        //     } else {
        //         setUserCodeError(true);
        //     }
        // });

        // props.setUserCode(trimmedUserCode);
        // props.setExpCondition('rec_and_rationale');
        // navigate(`/platform/${trimmedUserCode}`);
    };

    const handleDemoClick = () => {
        // set the demo state to true
        props.setIsDemo(true);

        // reset the userAnswer and userTracking data in Redux Store
        dispatch(resetAnswerState());
        dispatch(resetTrackingState());

        // directly navigate to the platform page
        navigate(`/platform/`);
    };

    return (
        <ThemeProvider theme={theme}>
            {/* Only show the welcome page if user is using a large enough screen */}
            {smallScreen 
            ?
                <Stack 
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={3}
                    sx={{ height: '100vh', width: '100vw', bgcolor: '#7ACCEF'}}
                    // style={{backgroundImage: `url(${background})`, backgroundSize: 'cover'}}
                >
                    <Typography variant="h2">
                        SenseMate
                    </Typography>
                    <Typography variant="h4" align="center" sx={{width:'90%'}}>
                        Qualitative coding enhanced.
                    </Typography>
                    <Typography variant="h5" align="center" sx={{width:'90%'}}>
                        Currently, SenseMate is not available on mobile devices. Please use a laptop or desktop computer.  
                    </Typography>
                </Stack>
            :
                <>
                <AppBar position="fixed" color="transparent">
                {/* Move button to the right side */}
                    <Toolbar>
                        <Typography variant="h4" component="div" sx={{ flexGrow: 1 }}>
                        </Typography>
                        <Button color="inherit" startIcon={<HelpIcon />}
                            onClick={handleClickOpenUserGuideDialog}
                        >
                            About SenseMate
                        </Button>
                    </Toolbar>
                </AppBar>
                <Stack 
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={3}
                    sx={{ height: '100vh', width: '100vw' }}
                    style={{backgroundImage: `url(${background})`, backgroundSize: 'cover'}}
                >
                    <Typography variant="h1">
                        SenseMate
                    </Typography>
                    <Typography variant="h3">
                        Qualitative coding enhanced.
                    </Typography>
                    {/* <TextField
                        error={userCodeError}
                        value={userCode}
                        helperText={userCodeError? "Invalid user code": ""}
                        variant="outlined"
                        sx={{ width: '50vw'}}
                        onChange={(event) => setUserCode(event.target.value)}
                    /> */}
                    <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={3}
                    >
                        <Tooltip title="Experience the complete version of SenseMate">
                            <Button onClick={handleClick} color="inherit" variant='bigButtons' size='large'>Start SenseMate</Button>
                        </Tooltip>
                        <Tooltip title="Get a taste of SenseMate through a demo">
                            <Button onClick={handleDemoClick} color="inherit" variant='bigButtons' size='large'>Sample SenseMate</Button>
                        </Tooltip>
                    </Stack>
                </Stack> 
                <UserGuide 
                    openUserGuideDialog={openUserGuideDialog} 
                    setOpenUserGuideDialog={setOpenUserGuideDialog}
                />
                </>
            }
            
        </ThemeProvider>
    )
}

export default StartPage;