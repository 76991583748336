import * as React from 'react';
import { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import theme from './common/theme';
import { Stack } from '@mui/system';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Grid from '@mui/material/Grid';

// redux stuff
import { useDispatch } from 'react-redux';
import { addUserTrackingData } from '../store/userTrackingSlice';

// icons
import BorderColorIcon from '@mui/icons-material/BorderColor';
import ContentCutIcon from '@mui/icons-material/ContentCut';

// data
import { code_to_color } from './common/constants';


function Highlighter(props) {
    // props include wordList, speaker_name, recommendationMap, highlightMap, code, setHighlightMap, snippet_id

    const dispatch = useDispatch(); 

    // const [highlightMap, setHighlightMap] = useState([]);
    const [recommendationMap, setRecommendationMap] = useState([]);
    // const [wordList, setWordList] = useState([]);
    const [highlightMode, setHighlightMode] = useState("highlight");
    const [showRecommendation, setShowRecommendation] = useState(false);

    const hightlightStyle = {"fontWeight": "bold", "color": `${code_to_color[props.code]}`};
    const recommendationStyle = {"backgroundColor": "Gainsboro"};
    
    useEffect(() => {
        setRecommendationMap(props.recommendationMap);
    }, [props])

    /** Checks if the index is within the bound */
    function isInBound(index, start, end) {
        return index >= start && index <= end;
    }

    /** On highlight mode change */
    function onHighlightModeChange(event, newMode) {
        if (newMode !== null) {
            // user tracking
            if (newMode === "highlight") {
                dispatch(addUserTrackingData({eventType: "selectHighlightMode", eventDetail: {"snippet_id": props.snippet_id, "code": props.code}}));
            } else {
                dispatch(addUserTrackingData({eventType: "selectEraseMode", eventDetail: {"snippet_id": props.snippet_id, "code": props.code}}));
            }
            setHighlightMode(newMode);
        }
    }

    /** On show recommendation from sensemate */
    function onShowRecommendation() {
        setShowRecommendation(true);
        // user tracking
        dispatch(addUserTrackingData({eventType: "showOriginalRec", eventDetail: {"snippet_id": props.snippet_id, "code": props.code}}));
    }

    /** On hide recommendation from sensemate */
    function onHideRecommendation() {
        setShowRecommendation(false);
    }

    /** Handle mouse up when user selects a section of the text */
    function onHandleMouseUp(e) {
        e.preventDefault()

        // https://developer.mozilla.org/en-US/docs/Web/API/Selection
        const selection = window.getSelection();
        const anchorNode = selection.anchorNode;
        const focusNode = selection.focusNode;
        const position = anchorNode.compareDocumentPosition(focusNode);

        let startIndex;
        let endIndex;
        if (position === Node.DOCUMENT_POSITION_FOLLOWING) {
            // forward selection
            startIndex = anchorNode.parentElement.id;
            endIndex = focusNode.parentElement.id;
        } else {
            //backward selection
            startIndex = focusNode.parentElement.id;
            endIndex = anchorNode.parentElement.id;
        }

        // This means the user only clicks on the paragraph without selecting any word.
        // We shouldn't highlight any text in this case
        if (startIndex === endIndex && selection.anchorOffset === selection.focusOffset) {
            return;
        }

        // user tracking
        dispatch(addUserTrackingData({eventType: "selectText", eventDetail: {"snippet_id": props.snippet_id, "code": props.code, "start_index": startIndex, 
                                        "end_index":endIndex}}));

        props.setHighlightMap(props.highlightMap.map((value, index) => {
            // Don't do anything when highlight mode is not selected
            if (highlightMode === null) {
                return value;
            }
            const newValue = highlightMode === "highlight" ? 1 : 0;
            return isInBound(index, startIndex, endIndex)
                ? newValue
                : value;
        }));
    }

    /** Get style to use for the word at given index. */
    function getStyle(index) {
        return {
            ...(props.highlightMap[index] === 1 ? hightlightStyle : {}),
            ...(showRecommendation && recommendationMap[index] ? recommendationStyle : {}),
        };
    }

    return (
        <ThemeProvider theme={theme}>
        {/* <Box> */}
            <Grid item xs={12}>
                <Stack direction={"row"} alignItems="center" justifyContent="space-between">
                    <ToggleButtonGroup
                        color="primary"
                        value={highlightMode}
                        exclusive={true}
                        onChange={onHighlightModeChange}
                        // size="small"
                    >
                        <ToggleButton value="highlight"
                            // sx={{fontWeight: 'bold'}}
                        >
                            <Stack
                                direction="row"
                                spacing={1}
                                alignItems="center"
                                justifyContent="center"
                            >
                                <BorderColorIcon />
                                {/* <div>Highlight</div> */}
                                <div>Select</div>
                            </Stack>
                        </ToggleButton>
                        <ToggleButton value="erase"
                            // sx={{fontWeight: 'bold'}}
                        >
                            <Stack
                                direction="row"
                                spacing={1}
                                alignItems="center"
                                justifyContent="center"
                            >
                                <ContentCutIcon />
                                {/* <div>Erase</div> */}
                                {/* <div>Unhighlight</div> */}
                                <div>Deselect</div>
                            </Stack>
                        </ToggleButton>
                    </ToggleButtonGroup>
                    {(recommendationMap.length > 0) &&
                        <Button 
                            onPointerEnter={onShowRecommendation}
                            onPointerLeave={onHideRecommendation}
                        >
                            View Sensemate's reason
                        </Button>
                    }
                </Stack>
            </Grid>
            <Grid item xs={12}>
                <Box sx={{ flexGrow: 1, border: `2px solid #9E9E9E`, padding:1, overflowY: "scroll", maxHeight: "200px", bgcolor:'white'}}>
                    <Typography sx={{color: theme.palette.primary.main, fontWeight: 'bold', fontSize:18, paddingBottom:0.5}}>
                        {props.speaker_name}
                    </Typography>

                    <Typography onMouseUp={onHandleMouseUp}
                        sx={{
                            '&:hover': {
                                cursor: 'text'
                            }
                        }}
                    >
                        {props.wordList.map((word, index) =>
                            <span key={index} id={index} style={getStyle(index)}>
                                {`${word} `}
                            </span>)
                        }
                    </Typography>
                </Box>
            </Grid>
        {/* </Box> */}
        </ThemeProvider>
    );
}

export default Highlighter;