import * as React from 'react';
import { useEffect, useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import theme from './common/theme';
import { Stack } from '@mui/system';
import { Typography } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';

// redux stuff
import { useSelector, useDispatch } from 'react-redux'
import { resetHighlightingInterfaceState, editNewThemeFeedback, editRecFeedback, editRecAction, removeCode, removeUnsureCode } from '../store/userAnswerSlice';
import { addUserTrackingData } from '../store/userTrackingSlice';

// other components
import Highlighter from './Highlighter';
import Tag from './common/Tag';
import BannerAd from './common/BannerAd';

// data
import { code_to_color, code_to_name } from './common/constants';


function HighlightingInterface(props) {
    // props include snippetData, recs, moveToNextTab
    // setSelectedSnippet, reselectSnippet, setReselectSnippet

    // get the highlighting_interface_state from the Redux store
    const highlighting_interface_state = useSelector(state => state.userAnswer.highlighting_interface_state);

    // get the relevant rec data if it exists
    const [recData, setRecData] = useState(undefined);
    useEffect(() => {
        if (highlighting_interface_state["type"] === "rec") {
            setRecData(props.recs.find(rec => rec["rec_id"] === highlighting_interface_state["rec_id"]));
        }
    }, [highlighting_interface_state, props.recs]);

    // create wordList variable
    const [wordList, setWordList] = useState([]);
    useEffect(() => {
        if (props.snippetData !== undefined) {
            setWordList(props.snippetData["text"].replace("\n", "").split(" ").filter(str => str !== ""));
        }
    }, [props.snippetData]);

    // get the rec_to_feedback data from the Redux store
    const rec_to_feedback = useSelector(state => state.userAnswer.rec_to_feedback);

    // get the new_theme_to_feedback data from the Redux store
    const new_theme_to_feedback = useSelector(state => state.userAnswer.new_theme_to_feedback);

    // state variable to keep track of whether user already gave feedback for this code
    const [hasGivenFeedback, setHasGivenFeedback] = useState(false);
    // state variable to keep track of the user submitted feedback
    const [highlightMap, setHighlightMap] = useState([]);
    // set highlightMap depending based on Redux store
    useEffect(() => {
        // if the code is a rec, then check if there is feedback for this rec in the Redux store
        if (highlighting_interface_state["type"] === "rec") {
            // check if there is feedback for this rec in the Redux store
            if (rec_to_feedback[highlighting_interface_state["rec_id"]].length > 0) {
                setHighlightMap(rec_to_feedback[highlighting_interface_state["rec_id"]]);
                setHasGivenFeedback(true);
            } 
            // if there is no feedback for this rec in the Redux store, then set highlightMap to be the rationale_mask
            else if (recData !== undefined) {
                setHighlightMap(recData["rationale_mask"]);
            }
        }
        // if the code is a new theme, then check if there is feedback for this new theme in the Redux store
        else if ((highlighting_interface_state["type"] === "new") && (props.snippetData !== undefined)) {
            // check if there is feedback for this new theme in the Redux store
            if ((new_theme_to_feedback[props.snippetData["snippet_id"]] !== undefined) && 
                    (new_theme_to_feedback[props.snippetData["snippet_id"]][highlighting_interface_state["code"]] !== undefined)) {
                setHighlightMap(new_theme_to_feedback[props.snippetData["snippet_id"]][highlighting_interface_state["code"]]);
                setHasGivenFeedback(true);
            }
            // if there is no feedback for this new theme in the Redux store, then set highlightMap to be an array of 0s
            else {
                setHighlightMap(new Array(wordList.length).fill(0));
            }
        }
    }, [highlighting_interface_state, props.snippetData, recData, wordList, rec_to_feedback, new_theme_to_feedback]);

    // state for checkbox
    const [checked, setChecked] = useState(false);

    const handleCheckBoxChange = (event) => {
        // update highlightMap when the checkbox is checked
        // when checkbox is selected, we want to set highlightMap to be an array of 0s
        if (event.target.checked) {
            setHighlightMap(new Array(wordList.length).fill(0));
            // user tracking
            dispatch(addUserTrackingData({eventType: "selectRejectRecCheckBox", eventDetail: {"snippet_id": props.snippetData["snippet_id"], 
                                            "rec_id": highlighting_interface_state["rec_id"]}}));
        } else {
            // user tracking
            dispatch(addUserTrackingData({eventType: "deselectRejectRecCheckBox", eventDetail: {"snippet_id": props.snippetData["snippet_id"], 
                                            "rec_id": highlighting_interface_state["rec_id"]}}));
        }
        // if user deselects checkbox, the highlightMap doesn't change back
        setChecked(event.target.checked);
    };

    // if user highlights anything after selecting the checkbox then checkbox shouldn't be selected anymore
    useEffect(() => {
        if (checked && highlightMap.includes(1)) {
            setChecked(false);
        }
    }, [highlightMap, checked]);

    const dispatch = useDispatch(); 

    // function to handle the cancel button (basically reset highlighting_interface_state in Redux store)
    const handleClickCancel = () => {
        // reset highlighting_interface_state in Redux store
        dispatch(resetHighlightingInterfaceState());
        // user tracking
        if (highlighting_interface_state["type"] === "rec") {
            dispatch(addUserTrackingData({eventType: "clickCancelHighlighting", eventDetail: {"snippet_id": props.snippetData["snippet_id"], 
                                            "code": recData["code"]}}));
        } else if (highlighting_interface_state["type"] === "new") {
            dispatch(addUserTrackingData({eventType: "clickCancelHighlighting", eventDetail: {"snippet_id": props.snippetData["snippet_id"], 
                                            "code": highlighting_interface_state["code"]}}));
        }
        // re-select the snippet if needed
        if (props.reselectSnippet) {
            // re-select the snippet
            props.setSelectedSnippet(props.snippetData["snippet_id"]);
            // reset the reselectSnippet state
            props.setReselectSnippet(false);
        }
    };

    // function to handle the submit button
    const handleClickSubmit = () => {
        // if the code is a rec, then update the rec_to_feedback data in the Redux store
        if (highlighting_interface_state["type"] === "rec") {
            dispatch(editRecFeedback({rec_id: highlighting_interface_state["rec_id"], feedback: highlightMap}));
            // update rec action if checkbox checked
            if (checked) {
                dispatch(editRecAction({rec_id: highlighting_interface_state["rec_id"], rec_action: "reject"}));
                // if the rec is in the codes of the snippet, then remove it
                dispatch(removeCode({snippet_id: props.snippetData["snippet_id"], code_to_remove: recData["code"]}));
                // if the rec is in the unsure codes of the snippet, then remove it
                dispatch(removeUnsureCode({snippet_id: props.snippetData["snippet_id"], code_to_remove: recData["code"]}));
                // move to the next rec if there is one
                props.moveToNextTab();
            }
            // user tracking
            dispatch(addUserTrackingData({eventType: "clickSubmitFeedback", eventDetail: {"snippet_id": props.snippetData["snippet_id"], 
                                            "code": recData["code"]}}));
        }
        // if the code is a new theme, then update the new_theme_to_feedback data in the Redux store
        else if (highlighting_interface_state["type"] === "new") {
            dispatch(editNewThemeFeedback({snippet_id: props.snippetData["snippet_id"], code: highlighting_interface_state["code"], 
                                            feedback: highlightMap}));
            // user tracking
            dispatch(addUserTrackingData({eventType: "clickSubmitFeedback", eventDetail: {"snippet_id": props.snippetData["snippet_id"], 
                                            "code": highlighting_interface_state["code"]}}));
        }
        // re-select the snippet if needed
        if (props.reselectSnippet) {
            // re-select the snippet
            props.setSelectedSnippet(props.snippetData["snippet_id"]);
            // reset the reselectSnippet state
            props.setReselectSnippet(false);
        }
        // reset highlighting_interface_state in Redux store
        dispatch(resetHighlightingInterfaceState());
    };

    return (
        <ThemeProvider theme={theme}>
            <Grid
                container
                spacing={2}
            >
            {/* Header of highlighting interface */}
            <Grid item xs={12}>
                <Stack
                    direction="row"
                    spacing={2}
                    alignItems="center"
                >
                    {hasGivenFeedback ?
                        // Editing feedback
                        <Typography variant="h6" sx={{color: theme.palette.primary.main, fontWeight: 'bold'}}>Your feedback on</Typography>
                    :
                        // Giving feedback for the first time
                        <Typography variant="h6" sx={{color: theme.palette.primary.main, fontWeight: 'bold'}}>Feedback on</Typography>
                    }
    
                    {(highlighting_interface_state["type"] === "new") ?
                        <Tag code={highlighting_interface_state["code"]} type={"normal"}/>
                    :
                        [recData !== undefined &&
                            <Tag code={recData["code"]} type={"normal"}/>
                        ]
                    }
                </Stack>
            </Grid>
            
            {/* Banner ad with description */}
            <Grid item xs={12}>
                {(highlighting_interface_state["type"] === "new") ?
                    // Banner ad shown for new themes 
                    <BannerAd type={"plain"} code={highlighting_interface_state["code"]} text={"<div>Use the \"Select\" and \"Deselect\" features in the toolbar to highlight the words associated with input_code_name. \"Select\" is chosen by default.</div>"}/>
                :
                    // Default banner ad
                    <BannerAd type={"plain"} text={"<div>Click the \"Select\" or \"Deselect\" buttons from the toolbar and then highlight a section of text to indicate whether it should be included in SenseMate’s reason or not. \"Select\" is chosen by default.</div>"}/>
                }
            </Grid>
            {/* Checkbox to automatically reject rec */}
            {(highlighting_interface_state["type"] === "rec") && (recData !== undefined) && (!hasGivenFeedback) &&
            <Grid item xs={12}>
                <FormControlLabel control={
                    <Checkbox
                        checked={checked}
                        onChange={handleCheckBoxChange}
                    />
                } label={
                    <div>None of this is relevant to <b style={{color: `${code_to_color[recData["code"]]}`}}>{code_to_name[recData["code"]]}</b>, reject recommendation</div>
                } />
            </Grid>
            }
            {/* Highlighter component */}
            {(props.snippetData !== undefined) && 
                [(highlighting_interface_state["type"] === "rec") ? 
                    [(recData !== undefined) && 
                        <Highlighter
                            key={0}
                            wordList={wordList}
                            speaker_name={props.snippetData["speaker_name"]}
                            recommendationMap={recData["rationale_mask"]}
                            highlightMap={highlightMap}
                            code={recData["code"]}
                            setHighlightMap={setHighlightMap}
                            snippet_id={props.snippetData["snippet_id"]}
                        />
                    ]
                :
                    <Highlighter
                        key={1}
                        wordList={wordList}
                        speaker_name={props.snippetData["speaker_name"]}
                        recommendationMap={[]}
                        highlightMap={highlightMap}
                        code={highlighting_interface_state["code"]}
                        setHighlightMap={setHighlightMap}
                        snippet_id={props.snippetData["snippet_id"]}
                    />
                ] 
            }
            {/* Submit feedback and cancel buttons */}
            <Grid item xs={12}>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Button variant="bigButtons" size="large"
                        onClick={handleClickSubmit}
                    >
                        Submit feedback
                    </Button>
                    <Button variant="bigButtons" size="large"
                        onClick={handleClickCancel}
                    >
                        Cancel
                    </Button>
                </Stack>
            </Grid>
            </Grid>
        </ThemeProvider>
    )
} 

export default HighlightingInterface;