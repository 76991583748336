import * as React from 'react';
import Button from '@mui/material/Button';

import { code_to_color, code_to_name } from './constants';

// icons
import FlagIcon from '@mui/icons-material/Flag';

// includes the special re-visit tags

function Tag(props) {
    return (
        <div>
            {props.type === "normal" &&
                <Button variant="tag" disabled style={{backgroundColor: code_to_color[props.code]}} 
                    sx={{minWidth:"175px", padding:"4px"}}
                    startIcon={props.code === "revisit" && <FlagIcon/>}
                >
                    {code_to_name[props.code]}
                </Button>
            }
            {props.type === "revisit" &&
                <Button variant="revisitTag" disabled 
                    style={{backgroundColor: code_to_color[props.code]}} 
                    sx={{minWidth:"175px", paddingTop:0, paddingBottom:0, paddingLeft:"4px", paddingRight:"4px"}}
                    startIcon={<FlagIcon sx={{paddingLeft: 0.5}}/>}
                >
                    Re-visit {code_to_name[props.code]}
                </Button>
            }
            {props.type === "lowerOpacity" &&
                <Button variant="lowerOpacityTag" disabled style={{backgroundColor: code_to_color[props.code]}} sx={{minWidth:"175px", padding:"4px"}}>
                    {code_to_name[props.code]}
                </Button>
            }
            {/* Text below is temp and only there to make sure the colors work */}
            {/* <Typography sx={{fontWeight: 'bold', color: code_to_color[props.code]}}>
                {code_to_name[props.code]}
            </Typography> */}
        </div>
    );
}

export default Tag;