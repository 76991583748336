// dict mapping from the code to the color
// all these colors are temporary for now
// Source = http://tsitsul.in/blog/coloropt/
// other possible colors '#00c6f8' (turquoise, a bit light); '#006e00' (green)
export const code_to_color = {
    'housing_affordability': '#c96eda', // lavender , old color #d163e6
    'covid-19': '#0d9968', // jade, old color #00a76c
    'quality_of_education': '#5e59d0', // indigo, old color #5954d6
    'sense_of_safety': '#e0a014', // yellow, old color #ebac23
    'community_values': '#098aef', // azure, old color #008cf9
    'transportation': '#0eaca0', // caribbean, old color #00bbad
    // 'processes': '#ff9287', // coral
    'race-based': '#a5470e', // brown, old color #b24502
    'income': '#878500', // olive, toned down color #7c7a0a
    'revisit': '#a90e58', // lipstick, old color #b80058
};

// dict mapping from the code to the formal name
export const code_to_name = {
    'housing_affordability': 'Housing Affordability',
    'covid-19': 'COVID-19',
    'quality_of_education': 'Quality of Education',
    'sense_of_safety': 'Sense of Safety',
    'community_values': 'Community Values',
    'transportation': 'Transportation',
    // 'processes': 'Processes',
    'race-based': 'Race-based Inequality',
    'income': 'Income',
    'revisit': 'Re-visit Story',
};

// mapping from the rec actrion to the name to include in the snippet rec component
export const rec_action_to_name = {
    "reject": "Not relevant", 
    "approve": "Approved", 
    "unsure": "Unsure", 
};

// mapping from code to the definition
export const code_to_definition = {
    'housing_affordability': 'Cost of housing and how affordable that cost is to residents, regardless of tenure (tenant/owner) and subsidy (e.g. workforce housing, public housing).',
    'covid-19': 'COVID-19, vaccines, masks, COVID tests, boosters, and the impacts of COVID-19 such as working from home, school closures, and jobs lost.',
    'quality_of_education': 'Education that empowers individuals and communities to get more control over their own situations and environments; education systems that focus on the importance of quality learners, quality learning environments, quality content, quality processes, and quality outcomes.',
    'sense_of_safety': 'Refers to feeling unsafe within daily life routines at home, in one\'s neighborhood, and throughout the city.',
    'community_values': 'Values instilled throughout the community; differences in values within and across communities.',
    'transportation': 'References to public transportation— like the MBTA, buses, and trains. This can include discussions about: the quality, affordability, accessibility, and safety of transportation.',
    // 'processes': 'References to successful and unsuccessful processes through which the public interfaces with government, such as voting, community engagement, campaigning, and other decision-making processes.',
    'race-based': 'Defined as lack of jobs, services, and goods based on skin color, ethnicity, and language.',
    'income': 'References to income/wages and wealth. This can include discussions about: one\'s personal income; satisfaction with their income; in/ability to increase their income; in/ability to build wealth; income inequality; the income/wage levels to be able to afford the cost of living in Boston.',
    'revisit': 'Use this code for stories that you want to double check or label later',
};

// list of codes (alphabetical order)
export const codes = [
    'community_values',
    'covid-19',
    'housing_affordability',
    'income',
    // 'processes',
    'quality_of_education',
    'race-based',
    'sense_of_safety',
    'transportation',
];
