import { createTheme } from '@mui/material/styles';

import { code_to_color } from './constants';

const defaultTheme = createTheme();

const theme = createTheme({
    components: {
      MuiButton: {
        styleOverrides: {
          // Override the default MUI Button component
          root: {
            // fontSize: '1rem',
            backgroundColor: '#CFD8DC',
            fontWeight: 'bold',
            color: defaultTheme.palette.primary.main,
            '&:hover' : {
                backgroundColor: '#BECAD0',
            },
          },
        },
        // add specific MUI Button variants, define via props
        variants: [
            {
                props: { variant: 'cheesecake' },
                style: {
                    textTransform: 'none',
                    border: `2px dashed ${defaultTheme.palette.primary.main}`,
                    color: defaultTheme.palette.primary.main,
                },
            },
            {
              props: { variant: 'bigButtons', size: 'large' },
              style: {
                  color: 'white',
                  backgroundColor: defaultTheme.palette.primary.main,
                  '&:hover' : {
                    backgroundColor: defaultTheme.palette.primary.dark,
                  },
                  fontSize: 16,
              },
          },
            {
              props: { variant: 'showExample' },
              style: {
                  color: defaultTheme.palette.primary.main,
                  backgroundColor: 'white',
                  size: 'small',
                  '&:hover' : {
                    backgroundColor: '#E5E6E4',
                  },
              },
            },
            {
              props: { variant: 'textButton' },
              style: {
                  color: defaultTheme.palette.primary.main,
                  backgroundColor: 'transparent'
              },
            },
            {
              props: { variant: 'sensemateVerified' },
              style: {
                  textTransform: 'none',
                  color: '#616161',
                  backgroundColor: 'transparent',
                  fontWeight: 'normal',
                  padding: 0,
                  '&:disabled' : {
                    color: '#616161',
                  },
              },
            },
            {
                props: { variant: 'yesButton' },
                style: {
                    color: defaultTheme.palette.success.main,
                },
            },
            {
                props: { variant: 'noButton' },
                style: {
                    color: defaultTheme.palette.error.dark,
                },
            },
            {
              props: { variant: 'tag' },
              style: {
                  textTransform: 'none',
                  color: 'white',
                  '&:disabled' : {
                    color: 'white',
                  },
              },
            },
            {
              props: { variant: 'revisitTag' },
              style: {
                  textTransform: 'none',
                  color: 'white',
                  '&:disabled' : {
                    color: 'white',
                  },
                  border: `4px solid ${code_to_color["revisit"]}`,
              },
            },
            {
              props: { variant: 'lowerOpacityTag' },
              style: {
                  textTransform: 'none',
                  opacity: 0.75,
                  color: 'black',
                  '&:disabled' : {
                    color: 'black',
                  },
              },
            },
            {
              props: { variant: 'expandRecs', size: 'large' },
              style: {
                textTransform: 'none',
                fontSize: 16,
                color: 'white',
                borderRadius: 28,
                backgroundColor: defaultTheme.palette.primary.main,
                '&:hover' : {
                  backgroundColor: defaultTheme.palette.primary.dark,
                },
              },
            },
        ]
      },
      MuiIconButton: {
        // add specific MUI IconButton variants, define via props
        variants: [
          {
              props: { variant: 'yesButton' },
              style: {
                  color: defaultTheme.palette.success.main,
              },
          },
          {
              props: { variant: 'noButton' },
              style: {
                  color: defaultTheme.palette.error.dark,
              },
          },
          {
            props: { variant: 'unsureButton' },
            style: {
                color: defaultTheme.palette.primary.main,
            },
          },
        ]
      },
      MuiToggleButton: {
        styleOverrides: {
          // Override the default MUI Button component
          root: {
            fontWeight: 'bold',
            "&.Mui-selected, &.Mui-selected:hover": {
              color: defaultTheme.palette.primary.main,
              backgroundColor: "#cfe4f9"
            }
          },
        },
      },
    },
  });

export default theme;