// Part of Redux Store that stores two things: 1) the codes that users apply to each snippet and 2) the actions (reject, approve, unsure) and feedback that users take on each recommendation
// This slice is needed in the filter bar, snippet, and codebook components
// addCode, addRecAction, addRecFeedback, selectSnippet, and editShownSnippets are all called when the user first enters the platform (after they go through the log-in page)
// Reference here for an example: https://github.com/mit-ccc/facilimate/blob/main/facilitation-scaffolding-app/src/components/MainPage.js#L48-L79

import { createSlice } from '@reduxjs/toolkit';

export const userAnswerSlice = createSlice({
    name: 'userAnswer',
    initialState: {
        snippet_to_codes: {}, // dictionary that maps snippet IDs to the codes that users have applied to them = {id1: [code1_id, code2_id, ...]}
        snippet_to_unsure_codes: {}, // dictionary that maps snippet IDs to the codes that users are unsure about = {id1: [code1_id, code2_id, ...]}
        rec_to_action: {}, // dictionary that maps recommendation IDs to the actions (reject, approve, unsure, none) that users took = {id1: 'reject', id2: 'approve', ...}
        rec_to_feedback: {}, // dictionary that maps recommendation IDs to the feedback on the rationales that users provided = {id1: [0, 1, 1, 0, ...], ...}
        quick_question_answers: {}, // dictionary that maps rec and quick question ids to "yes", "no", or "unsure" = {id1: {id2: "yes", id3: "no", ...}, ...}
        // selectedSnippet: -1, // ID of the snippet that is currently selected = id1; ids are integers
        shownSnippets: [], // list of the IDs of the snippets that are currently shown, default is list of all snippets (modified by the filter bar) = [id1, id2, ...]
        new_theme_to_feedback: {}, // dictionary that maps new theme IDs to the feedback on the new theme that users provided = {id1: {id2: [0, 1, 1, 0, ...],...} ...}
        highlighting_interface_state: {"snippet_id": -1, "type": "none"}, // information about the state of the highlighting interface. this will always have a "type" field that can be "none" (don't show interface), "rec" (show interface for a rec), or "new" (show interface for a new theme)
        snippet_to_new_themes: {}, // dictionary that maps snippet IDs to the new themes that users have applied and need to show the banner ad for = {id1: {"code_name": true/false, ...}, ...}
    },
    reducers: {
        // set the entire state to the given state
        setState: (state, action) => {
            // console.log(action.payload);
            state.snippet_to_codes = action.payload.snippet_to_codes;
            state.snippet_to_unsure_codes = action.payload.snippet_to_unsure_codes;
            state.rec_to_action = action.payload.rec_to_action;
            state.rec_to_feedback = action.payload.rec_to_feedback;
            state.quick_question_answers = action.payload.quick_question_answers;
            state.shownSnippets = action.payload.shownSnippets;
            state.new_theme_to_feedback = action.payload.new_theme_to_feedback;
            state.highlighting_interface_state = action.payload.highlighting_interface_state;
            state.snippet_to_new_themes = action.payload.snippet_to_new_themes;
        },
        // reset state to initialState
        resetAnswerState: (state) => {
            state.snippet_to_codes = {};
            state.snippet_to_unsure_codes = {};
            state.rec_to_action = {};
            state.rec_to_feedback = {};
            state.quick_question_answers = {};
            state.shownSnippets = [];
            state.new_theme_to_feedback = {};
            state.highlighting_interface_state = {"snippet_id": -1, "type": "none"};
            state.snippet_to_new_themes = {};
        },
        // adds a snippet to the store (called when user first enters the platform)
        addSnippet: (state, action) => {
            // action.payload contains the snippet_id
            state.snippet_to_codes[action.payload] = [];
            state.snippet_to_unsure_codes[action.payload] = [];
            state.snippet_to_new_themes[action.payload] = {};
            // add snippet if it's not already in there
            if (!state.shownSnippets.includes(action.payload)) {
                state.shownSnippets.push(action.payload);
            }
        },
        // adds a rec to the story (called when user first enters the platform)
        addRec: (state, action) => {
            // action.payload contains the rec_id
            state.rec_to_action[action.payload] = "none";
            state.rec_to_feedback[action.payload] = []; 
            state.quick_question_answers[action.payload] = {};
        },
        // adds a code to a snippet
        addCode: (state, action) => {
            // action.payload contains the snippet_id and code
            const { snippet_id, code } = action.payload
            // add code it it's not added already
            if (!state.snippet_to_codes[snippet_id].includes(code)) {
                state.snippet_to_codes[snippet_id].push(code);
            }
        },
        // adds an unsure code to a snippet
        addUnsureCode: (state, action) => {
            // action.payload contains the snippet_id and code
            const { snippet_id, code } = action.payload
            // add code it it's not added already
            if (!state.snippet_to_unsure_codes[snippet_id].includes(code)) {
                state.snippet_to_unsure_codes[snippet_id].push(code);
            }
        },
        // add a new theme to a snippet
        addNewTheme: (state, action) => {
            // action.payload contains the snippet_id and new theme
            const { snippet_id, new_theme } = action.payload
            // add new theme it it's not added already
            if (state.snippet_to_new_themes[snippet_id][new_theme] === undefined) {
                state.snippet_to_new_themes[snippet_id][new_theme] = true;
            }
        },
        // edit the boolean field of the new theme and set it to false
        editNewTheme: (state, action) => {
            // action.payload contains the snippet_id and new theme
            const { snippet_id, new_theme } = action.payload
            // set the value of the new theme to false
            if (state.snippet_to_new_themes[snippet_id][new_theme] !== undefined) {
                state.snippet_to_new_themes[snippet_id][new_theme] = false;
            }
        },
        // removes a code from a snippet
        removeCode: (state, action) => {
            // action.payload contains the snippet_id and code
            const { snippet_id, code_to_remove } = action.payload
            state.snippet_to_codes[snippet_id] = state.snippet_to_codes[snippet_id].filter(code => code !== code_to_remove);
        },
        // removes an unsure code from a snippet
        removeUnsureCode: (state, action) => {
            // action.payload contains the snippet_id and code
            const { snippet_id, code_to_remove } = action.payload
            state.snippet_to_unsure_codes[snippet_id] = state.snippet_to_unsure_codes[snippet_id].filter(code => code !== code_to_remove);
        },
        // edits an action (reject, approve, unsure) to a recommendation
        editRecAction: (state, action) => {
            // action.payload contains the rec_id and action
            const { rec_id, rec_action } = action.payload
            state.rec_to_action[rec_id] = rec_action;
        },
        // edits feedback to a recommendation
        editRecFeedback: (state, action) => {
            // action.payload contains the rec_id and feedback
            const { rec_id, feedback } = action.payload
            state.rec_to_feedback[rec_id] = feedback;
        },
        // edits an answer to a quick question
        editQuickQuestionAnswer: (state, action) => {
            // action.payload contains the rec_id, question_id, and answer
            const { rec_id, question_id, answer } = action.payload
            state.quick_question_answers[rec_id][question_id] = answer;
        },
        // edits feedback to a recommendation
        editNewThemeFeedback: (state, action) => {
            // action.payload contains the rec_id and feedback
            const { snippet_id, code, feedback } = action.payload
            if (!(snippet_id in state.new_theme_to_feedback)) {
                state.new_theme_to_feedback[snippet_id] = {};
            } 
            state.new_theme_to_feedback[snippet_id][code] = feedback;
        },
        // sets the highlighting_interface_state
        setHighlightingInterfaceState: (state, action) => {
            // action.payload contains the snippet_id
            state.highlighting_interface_state = action.payload;
        },
        // reset the selected snippet (when user de-selects the snippet)
        resetHighlightingInterfaceState: (state, action) => {
            state.highlighting_interface_state = {"snippet_id": -1, "type": "none"};
        },
        // edits the list of shown snippets (via the filter bar); actual filtering code will be in the filter bar component
        editShownSnippets: (state, action) => {
            // action.payload contains the list of snippet_ids
            state.shownSnippets = action.payload;
        },
    },
});

export const { setState, resetAnswerState, addSnippet, addRec, addCode, addUnsureCode, addNewTheme, editNewTheme, removeCode, removeUnsureCode, editRecAction, editRecFeedback, editQuickQuestionAnswer, editNewThemeFeedback, setHighlightingInterfaceState, resetHighlightingInterfaceState, editShownSnippets } = userAnswerSlice.actions;

export default userAnswerSlice.reducer;