import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import { Typography } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import theme from './common/theme';
import { Stack } from '@mui/system';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Collapse } from '@mui/material';
import Button from '@mui/material/Button';

// redux stuff
import { useSelector, useDispatch } from 'react-redux'
import { setHighlightingInterfaceState, editNewTheme } from '../store/userAnswerSlice';
import { addUserTrackingData } from '../store/userTrackingSlice';

// icons
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

// other components
// import Tag from './common/Tag';
import SnippetTag from './SnippetTag';
import SnippetRecs from './SnippetRecs';
import BannerAd from './common/BannerAd';
import HighlightingInterface from './HighlightingInterface';

// data
import { code_to_color, code_to_name } from './common/constants';

function Snippet(props) {
    // the things that get passed as props are: 
    // id, snippetJson, recJson, expCondition 
    // selectedSnippet, setSelectedSnippet

    // // state variable to keep track of whether the highlighting interface should be shown
    // const [showHighlightInterface, setShowHighlightInterface] = useState(False); 

    // get the highlighting interface state from the redux store
    const highlightingInterfaceState = useSelector(state => state.userAnswer.highlighting_interface_state);

    // get the rec_to_feedback data from the Redux store
    const rec_to_feedback = useSelector(state => state.userAnswer.rec_to_feedback);

    // get the codes that were selected by the user for this snippet
    const snippet_to_codes = useSelector(state => state.userAnswer.snippet_to_codes);
    const codes = snippet_to_codes[props.id];

    // get the unsure codes that were selected by the user for this snippet
    const snippet_to_unsure_codes = useSelector(state => state.userAnswer.snippet_to_unsure_codes);
    const unsure_codes = snippet_to_unsure_codes[props.id];

    // get the new themes that were selected by the user for this snippet
    const snippet_to_new_themes = useSelector(state => state.userAnswer.snippet_to_new_themes);
    const new_themes = snippet_to_new_themes[props.id];

    // create a ref so we can automatically scroll to the top of the snippet when needed
    const snippetRef = useRef(null);
    const scrollToSnippetTop = 
        () => snippetRef.current.scrollIntoView();

    // get the data for the particular snippet
    const [snippetData, setSnippetData] = useState(undefined);
    // get the relevant recs for the snippet
    const [recs, setRecs] = useState([]);
    useEffect(() => {
        // get the snippet data
        if ((props.snippetJson !== undefined) && (props.snippetJson["snippets"] !== undefined)) {
            var data = props.snippetJson["snippets"].filter((snippet) => {
                if (snippet["snippet_id"] === props.id) {
                    return snippet;
                }  
            })[0];
            data["wordList"] = data["text"].replace("\n", "").split(" ").filter(str => str !== "");
            setSnippetData(data);

            // get all the recs for a particular snippet
            if ((props.recJson !== undefined) && (props.recJson["recs"] !== undefined)) {
                const filtered_recs = props.recJson["recs"].filter((rec) => {
                    if (rec["snippet_id"] === props.id) {
                        return rec;
                    }  
                });
                // go through the recs and update the colors
                filtered_recs.forEach((rec) => {
                    // replace the placeholder color with the right one
                    // const color = code_to_color[rec["code"]];
                    // rec["rationale_html"] = rec["rationale_html"].replaceAll("input_code_color", color);
                    rec["name"] = code_to_name[rec["code"]];
                });
                // console.log(filtered_recs);
                setRecs(filtered_recs);
            }
        }
    }, [props.snippetJson, props.id, props.recJson]);
    
    // state variable to keep track of which rec's reason is shown (if any)
    const [shownRec, setShownRec] = useState(-1);

    // useEffect that updates a variable to keep track of which rec to show the reason for
    const [shownRecData, setShownRecData] = useState(undefined);
    useEffect(() => {
        if ((recs.length > 0) && (shownRec !== -1)) {
            const filtered_rec = recs.filter((rec) => {
                if (rec["rec_id"] === shownRec) {
                    return rec;
                }
            })[0];
            // console.log(filtered_rec);
            setShownRecData(filtered_rec);
        }
    }, [shownRec, recs]);

    // code to update the redux store when user clicks on a button to update the action of a rec
    const dispatch = useDispatch(); 

    // state variable to keep track of whether recs are expanded or not
    const [expanded, setExpanded] = useState(false);

    // function to handle the click on the expand button
    const handleExpandClick = () => {
        // user tracking
        if (expanded) {
            dispatch(addUserTrackingData({eventType: "collapseRecs", eventDetail: {"snippet_id": props.id}}));
        } else {
            dispatch(addUserTrackingData({eventType: "expandRecs", eventDetail: {"snippet_id": props.id}}));
        }
        setExpanded(!expanded);
    };

    // state variable to remember whether a snippet is selected or not
    const [selected, setSelected] = useState(false);
    useEffect(() => {
        if (props.selectedSnippet === props.id) {
            setSelected(true);
        } else {
            setSelected(false);
        }
    }, [props.selectedSnippet, props.id]);

    // function to handle when a snippet is clicked
    const handleSnippetClick = (event) => {
        // console.log(event.target.tagName);
        // print classnnames of the target
        // console.log(event.target.classList);
        if ((highlightingInterfaceState["snippet_id"] !== props.id) && (event.target.tagName !== "BUTTON") && 
                (event.target.tagName !== "svg") &&  (event.target.tagName !== "path") &&
                (!event.target.classList.contains("noSnippetClick")) &&
                (!event.target.classList.contains("MuiAccordionSummary-content"))) 
        {
            if (props.selectedSnippet === props.id) {
                // if the snippet is already selected, then we want to deselect it
                props.setSelectedSnippet(undefined);
                // user tracking
                dispatch(addUserTrackingData({eventType: "deselectSnippet", eventDetail: {"snippet_id": props.id}}));
            } else {
                // if the snippet is not selected, then we want to select it
                props.setSelectedSnippet(props.id);
                // user tracking
                dispatch(addUserTrackingData({eventType: "selectSnippet", eventDetail: {"snippet_id": props.id}}));
            }
        }
    };

    // state variable to keep track of whether to re-select the snippet after exiting the highlighting interface
    const [reselectSnippet, setReselectSnippet] = useState(false);

    // deselect snippet if highlightingInterfaceState is opened
    useEffect(() => {
        if (highlightingInterfaceState["snippet_id"] === props.id) {
            props.setSelectedSnippet(undefined);
            if (selected) {
                setReselectSnippet(true);
            }
        }
    }, [highlightingInterfaceState, props.id]);

    // state variable to see if user gave feedback for this rec 
    const [feedbackGiven, setFeedbackGiven] = useState(false);
    useEffect(() => {
        // check if there is feedback for this rec in the Redux store
        if (shownRec !== -1) {
            if (rec_to_feedback[shownRec].length > 0) {
                setFeedbackGiven(true);
            } 
        }
    }, [rec_to_feedback, shownRec]);


    // state variable to keep track of whether the new theme banner ad is shown or not
    const [showNewThemeBanner, setShowNewThemeBanner] = useState(false);
    useEffect(() => {
        // go through each theme in new_themes and check if any of them have a true value
        for (const theme in new_themes) {
            if (new_themes[theme]) {
                setShowNewThemeBanner(true);
                return;
            }
        }
        setShowNewThemeBanner(false);
    }, [new_themes]);

    // user clicked yes in new theme banner ad
    const handleClickYes = (code) => {
        // console.log("Yes button clicked");
        // update highlighting_interface_state for an existing rec
        dispatch(setHighlightingInterfaceState({"snippet_id": props.id, "type": "new", "code": code}));
        // user tracking
        dispatch(addUserTrackingData({eventType: "clickYesInNewThemeBanner", eventDetail: {"snippet_id": props.id, "code": code}}));
        // set showAd to false
        dispatch(editNewTheme({snippet_id: props.id, new_theme: code})); 
        // scroll to the top of the snippet
        scrollToSnippetTop();
    };

    // user clicked no in new theme banner ad
    const handleClickNo = (code) => {
        // console.log("No button clicked");
        // set showAd to false
        dispatch(editNewTheme({snippet_id: props.id, new_theme: code})); 
        // user tracking
        dispatch(addUserTrackingData({eventType: "clickNoInNewThemeBanner", eventDetail: {"snippet_id": props.id, "code": code}}));
        scrollToSnippetTop();
    };


    // function to run when user clicks on help sensemate improve or edit feedback
    const handleClickHelpImprove = () => {
        // reset the showRec to -1
        setShownRec(-1);
        // update highlighting_interface_state for an existing rec
        dispatch(setHighlightingInterfaceState({"snippet_id": props.id, "type": "rec", "rec_id": shownRec}));
        // user tracking
        dispatch(addUserTrackingData({eventType: "clickHelpImproveFromShownReason", eventDetail: {"snippet_id": props.id, "rec_id": shownRec}}));
        // scroll to the top of the snippet
        scrollToSnippetTop();
    } 

    // keeping tack of the current tab (i.e. rec)
    const [tabValue, setTabValue] = React.useState(0);

    // function to move to the next SnippetRec
    // function to move to the next tab
    // add a time delay so that the user can see the feedback
    const moveToNextTab = () => {
        if (tabValue < recs.length - 1) {
            setTimeout(() => {
                setTabValue(tabValue + 1);
            }, 700);
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ flexGrow: 1, padding:2,
                        border: `2px solid ${selected ? "#1565C0" : "#9E9E9E"}`, 
                        bgcolor: `${selected ? "#dceefc" : "#FFFFFF"}`,
                        '&:hover': {
                            cursor: 'pointer',
                            bgcolor: `${selected ? "#d5eafb" : "#e8e8e8"}`,   
                        },
                        ...((highlightingInterfaceState["snippet_id"] === props.id) && {
                            flexGrow: 1, 
                            padding:2,
                            border: `2px solid #9E9E9E`, 
                            bgcolor: `#FFFFFF`,
                            '&:hover': {
                                cursor: 'default',
                                bgcolor: `#FFFFFF`,   
                            },
                        }),
                    }} 
                // ref={snippetRef} 
                onClick={handleSnippetClick}
            >

            {(highlightingInterfaceState["snippet_id"] === props.id) &&
                <HighlightingInterface snippetData={snippetData} recs={recs} moveToNextTab={moveToNextTab}
                    setSelectedSnippet={props.setSelectedSnippet}
                    reselectSnippet={reselectSnippet} setReselectSnippet={setReselectSnippet}
                />
            }

            {(highlightingInterfaceState["snippet_id"] !== props.id) && 
            <Grid
                container
                spacing={3}
                ref={snippetRef} 
            >   

                {/* Take care of the different conditions for a snippet */}

                {/* Add the banner ads for the new_themes */}
                {selected && showNewThemeBanner && (props.expCondition !== "control") &&
                    <Grid item xs={12}>
                        <Stack
                            direction="column"
                            spacing={1}
                        >
                            {Object.keys(new_themes).map((code) => {
                                if (new_themes[code]) {
                                 return (
                                    <BannerAd key={"new"+code} type={"infoWithButtons"} code={code} 
                                        handleClickYes={() => handleClickYes(code)} 
                                        handleClickNo={() => handleClickNo(code)} 
                                        selected={selected}
                                        text={"Hey! Wanna highlight the words associated with input_code_name and help SenseMate improve?"}/>
                                );}
                            })}
                        </Stack>
                    </Grid>
                }

                {/* Add the codes on top of the snippet */}
                {((unsure_codes.length > 0) || (codes.length > 0)) &&
                    <Grid item xs={12}>
                        <Stack
                            direction="column"
                            spacing={1.5}
                        >
                            {/* Add in the revisit whole story code if applicable */}
                            {codes.includes("revisit") &&
                                <SnippetTag code={"revisit"} snippet_id={props.id} tagType={"normal"} shownRec={shownRec} 
                                    setShownRec={setShownRec} recId={-1} expCondition={props.expCondition}
                                />
                            }

                            {/* Add in the unsure codes */}
                            {unsure_codes.map((code, index) => {
                                var recId = -1;
                                const rec = recs.filter((rec) => {
                                    if (rec["code"] === code) {
                                        return rec;
                                }});
                                if (rec.length > 0) {
                                    recId = rec[0]["rec_id"];
                                } 
                                return (
                                    <SnippetTag key={index} snippet_id={props.id} code={code} tagType={"revisit"} shownRec={shownRec} 
                                        setShownRec={setShownRec} recId={recId} expCondition={props.expCondition}
                                    />
                                )
                                })
                            }
                            
                            {/* Add in the rest of the codes */}
                            {codes.map((code, index) => {
                                if (code !== "revisit") {
                                    var recId = -1;
                                    const rec = recs.filter((rec) => {
                                        if (rec["code"] === code) {
                                            return rec;
                                    }});
                                    if (rec.length > 0) {
                                        recId = rec[0]["rec_id"];
                                    } 
                                    return (
                                        <SnippetTag key={index} snippet_id={props.id} code={code} tagType={"normal"} shownRec={shownRec} 
                                            setShownRec={setShownRec} recId={recId} expCondition={props.expCondition}
                                        />
                                    )
                                }})
                            }
                        </Stack>
                    </Grid>
                }
                
                {/* add the view reason banner ad if needed */}
                {(shownRec !== -1) && (props.expCondition === "rec_and_rationale") &&
                    <Grid item xs={12}>
                        <BannerAd type={"plain"} selected={selected}
                            code={recs.filter((rec) => {
                                if (rec["rec_id"] === shownRec) {
                                    return rec;
                                }})[0]["code"]} 
                            text={"<div>The <b input_code_color>bolded words</b> are what SenseMate paid attention to when recommending input_code_name</div>"}
                        />
                    </Grid>
                }

                {/* add the snippet content */}
                <Grid item xs={12}
                > 
                    <Box sx={{ flexGrow: 1, border: `2px solid #9E9E9E`, padding:1, overflowY: "scroll", maxHeight: "200px", bgcolor:'white'}}>
                    {/* Display speaker name */}
                    {(snippetData !== undefined) &&
                        <Typography sx={{color: theme.palette.primary.main, fontWeight: 'bold', fontSize:18, paddingBottom:0.5}}>
                            {snippetData["speaker_name"]}
                        </Typography>
                    }
                    {/* Display normal text if shownRec is -1 */}
                    {(snippetData !== undefined) && (shownRec === -1) &&
                        <Typography 
                        >
                            {snippetData["text"]}
                        </Typography>
                    }
                    {/* Display the rec reason if shownRec is not -1 */}
                    {(snippetData !== undefined) && (shownRec !== -1) && (shownRecData !== undefined) 
                            && (props.expCondition === "rec_and_rationale") &&
                        <Stack
                            direction="column"
                            spacing={1}
                            alignItems="flex-end"
                        >
                        <Typography 
                        >
                            {
                                shownRecData["rationale_mask"].map((mask, index) => {
                                    if (mask === 0) {
                                        return (
                                            <span key={index}>
                                                {`${snippetData["wordList"][index]} `}
                                            </span>
                                        )
                                    } else {
                                        return (
                                            <b key={index} style={{color: `${code_to_color[shownRecData["code"]]}`}}>
                                                {`${snippetData["wordList"][index]} `}
                                            </b>
                                        )
                                    }
                                })
                            }
                        </Typography>
                        {!feedbackGiven &&
                            <Button
                                onClick={handleClickHelpImprove}
                            >
                                help improve SenseMate’s reason
                            </Button>
                        }
                        </Stack>
                    }
                    </Box>
                </Grid>
                

                {/* Display the recs */}
                {(recs.length > 0) && (props.expCondition !== "control") &&
                    <Grid item xs={12}>
                        <Stack
                            direction="column"
                            spacing={2}
                        >
                            <Stack
                                direction="row"
                                spacing={2}
                                alignItems="center"
                            >
                                {/* <Typography variant="h6" sx={{color: theme.palette.primary.main, fontWeight: 'bold'}}>SenseMate’s Recommendations</Typography> */}
                                <Button variant="expandRecs" size="large" onClick={handleExpandClick} startIcon={expanded ? <ExpandLessIcon />: <ExpandMoreIcon />}>{expanded ? "Collapse SenseMate’s Recommendations": "Expand SenseMate’s Recommendations"}</Button>
                            </Stack>
                            <Collapse in={expanded} timeout="auto" unmountOnExit>
                                <Stack
                                    direction="column"
                                    spacing={2}
                                >
                                <Typography>Some recommendations are more relevant than others, so please accept or reject as many as you’d like</Typography>
                                <SnippetRecs snippet_id={props.id} recs={recs} shownRec={shownRec} 
                                    setShownRec={setShownRec} scrollToSnippetTop={scrollToSnippetTop}
                                    tabValue={tabValue} moveToNextTab={moveToNextTab} setTabValue={setTabValue}
                                    expCondition={props.expCondition}
                                />
                                </Stack>
                            </Collapse>
                        </Stack>
                    </Grid>
                }
                
            </Grid>
            }
            </Box>
        </ThemeProvider>
    );
}

export default Snippet;