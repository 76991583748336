import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { ThemeProvider } from '@mui/material/styles';
import theme from './common/theme';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';

// redux stuff
import { useSelector, useDispatch } from 'react-redux'
import { editQuickQuestionAnswer } from '../store/userAnswerSlice';
import { addUserTrackingData } from '../store/userTrackingSlice';

// Icons
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import HelpIcon from '@mui/icons-material/Help';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import FavoriteIcon from '@mui/icons-material/Favorite';

// other components
import { code_to_name, code_to_color } from './common/constants';

function QuickQuestions(props) {
    // props that are passed in are rec, title, moveToNextTab

    // ref to keep track of where to slide from
    const containerRef = useRef(null);

    // get the quick question answers from Redux state
    const quick_question_answers = useSelector(state => state.userAnswer.quick_question_answers)[props.rec["rec_id"]];

    // state variable to keep track of whether user is answering the questions for the first time
    const [firstTime, setFirstTime] = useState(true);
    useEffect(() => {
        // if the quick_question_answers is not undefined, then the user has answered the questions before
        if (Object.keys(quick_question_answers).length > 0) {
            setFirstTime(false);
        }
    }, []);

    // get the current feedback from the user
    // const rec_feedback = useSelector(state => state.userAnswer.rec_to_feedback)[props.rec["rec_id"]];
    
    const dispatch = useDispatch(); 

    // TEMP function for test
    // useEffect(() => {
    //     console.log(quick_question_answers);
    // }, [quick_question_answers]);

    // state variable for whether accordian is expanded or not
    const [expanded, setExpanded] = useState(true);
    // set expanded when user clicked on the code
    const handleAccordionClick = () => {
        // user tracking
        if (expanded) {
            dispatch(addUserTrackingData({eventType: "collapseQuickQs", eventDetail: {"rec_id": props.rec["rec_id"]}}));
        } else {
            dispatch(addUserTrackingData({eventType: "expandQuickQs", eventDetail: {"rec_id": props.rec["rec_id"]}}));
        }
        setExpanded(!expanded);
    }

    // index keeping track of which quick question we are on
    const [index, setIndex] = useState(0);
    // set the index to the first unanswered question
    useEffect(() => {
        // iterate through props.rec["quick_questions"].length and return the first index that isn't in quick_question_answers
        for (let i = 0; i < props.rec["quick_questions"].length; i++) {
            if (quick_question_answers[i] === undefined) {
                setIndex(i);
                return;
            }
        }
        // if all questions are answered, set index to the last question
        setIndex(props.rec["quick_questions"].length);
    }, [quick_question_answers, props.rec]);

    // create state variables for slide transition
    const [slideIn, setSlideIn] = useState(true);
    const [slideDirection, setSlideDirection] = useState('down');

    // function to navigate to the next rec once the user has answered all the quick questions
    const moveToNextRec = () => {
        setTimeout(() => {
            props.moveToNextTab();
        }, 1000);
    };

    // function to navigate to next quick question
    const navigateToNext = () => {
        if (index < props.rec["quick_questions"].length) {
            setSlideDirection('right');
            setSlideIn(false);

            setTimeout(() => {
                setIndex(index + 1);
                setSlideDirection('left');
                setSlideIn(true);
            }, 300);

            if ((index === props.rec["quick_questions"].length - 1) && firstTime) {
                // console.log("moving to next rec");
                moveToNextRec();
                setFirstTime(false);
            }
        }
    };

    // function called when user clicks the next button
    const handleClickNext = () => {
        navigateToNext();
        // user tracking
        dispatch(addUserTrackingData({eventType: "clickNextQuickQ", eventDetail: {"rec_id": props.rec["rec_id"], "old_index": index, 
                    "new_index": index+1}}));
    };

    // function to navigate to previous quick question
    const navigateToPrev = () => {
        if (index > 0) {
            setSlideDirection('left');
            setSlideIn(false);

            setTimeout(() => {
                setIndex(index - 1);
                setSlideDirection('right');
                setSlideIn(true);
            }, 300);
        }
    };

    // function called when user clicks the previous button
    const handleClickPrev = () => {
        navigateToPrev();
        // user tracking
        dispatch(addUserTrackingData({eventType: "clickPrevQuickQ", eventDetail: {"rec_id": props.rec["rec_id"], "old_index": index, 
                    "new_index": index-1}}));
    };

    // function that handles when user click yes for one of the quick questions
    const handleClickYes = () => {
        // update quick_question_answers in Redux state
        dispatch(editQuickQuestionAnswer({rec_id: props.rec["rec_id"], question_id: index, answer: "yes"}));
        // user tracking
        dispatch(addUserTrackingData({eventType: "clickYesQuickQ", eventDetail: {"rec_id": props.rec["rec_id"], "question_id": index}}));
        navigateToNext();
    };

    // function that handles when user clicks unsure for one of the quick questions
    const handleClickUnsure = () => {
        // update quick_question_answers in Redux state
        dispatch(editQuickQuestionAnswer({rec_id: props.rec["rec_id"], question_id: index, answer: "unsure"}));
        // user tracking
        dispatch(addUserTrackingData({eventType: "clickUnsureQuickQ", eventDetail: {"rec_id": props.rec["rec_id"], "question_id": index}}));
        navigateToNext();
    };

    // function that handles when user clicks no for one of the quick questions
    const handleClickNo = () => {
        // update quick_question_answers in Redux state
        dispatch(editQuickQuestionAnswer({rec_id: props.rec["rec_id"], question_id: index, answer: "no"}));
        // // update rec_to_feedback in Redux state
        // // (for now I'm not doing that because that makes the quick questions disappear)
        // if (rec_feedback.length > 0) {
        //     // if there is already feedback for a rec then edit that one
        //     var mask = [...rec_feedback];
        // } else {
        //     // get the current mask
        //     var mask = [...props.rec["rationale_mask"]];
        // }
        // // console.log(mask);
        // // set the relevant part of the mask to 0
        // const quick_q = props.rec["quick_questions"].filter((q) => {
        //     if (q["id"] === index) { 
        //         return q
        //     }
        // })[0];
        // for (var i = quick_q["start_index"]; i < quick_q["end_index"]; i++) {
        //     mask[i] = 0;
        // }
        // console.log(mask);
        // dispatch(editRecFeedback({rec_id: props.rec["rec_id"], feedback:mask}));
        // user tracking
        dispatch(addUserTrackingData({eventType: "clickNoQuickQ", eventDetail: {"rec_id": props.rec["rec_id"], "question_id": index}}));
        navigateToNext();
    };

    return (
        <ThemeProvider theme={theme}>
        <Accordion sx={{border: `1px solid #9E9E9E`, width:'100%', overflow: 'hidden'}} 
            className='noSnippetClick' ref={containerRef}
            expanded={expanded} onChange={handleAccordionClick}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon className='noSnippetClick'/>}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className='noSnippetClick'
            >
            <Typography className='noSnippetClick'>{props.title}</Typography>
            </AccordionSummary>
            <AccordionDetails className='noSnippetClick'>
                <Slide in={slideIn} direction={slideDirection} container={containerRef.current} className='noSnippetClick'>
                <div className='noSnippetClick'>
                {(index === props.rec["quick_questions"].length) &&
                    <Stack
                        direction="row"
                        justifyContent={"center"}
                        alignItems={"center"}
                        spacing={1}
                        className='noSnippetClick'
                    >
                        <FavoriteIcon sx={{color:'#7b1fa2'}} className='noSnippetClick'/>
                        <Typography className='noSnippetClick'>Thanks for answering all the questions!</Typography>
                    </Stack>
                }
                {(index < props.rec["quick_questions"].length) &&
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        spacing={1}
                        className='noSnippetClick'
                    >
                    <Stack
                        direction="column"
                        spacing={1}
                        alignItems="flex-start"
                        className='noSnippetClick'
                    >
                        <Typography className='noSnippetClick'>
                            Is the phrase "<b className='noSnippetClick' style={{color: `${code_to_color[props.rec["code"]]}`}}>{props.rec["quick_questions"].filter((q) => {
                                            if (q["id"] === index) { 
                                                return q
                                            }})[0]["text"]}</b>" relevant to <b className='noSnippetClick' style={{color: `${code_to_color[props.rec["code"]]}`}}>{code_to_name[props.rec["code"]]}</b> in this story?
                        </Typography>
                        {/* TODO: implement this feature? not necessary but would be nice to have*/}
                        {/* <Button variant="textButton" size="small"
                            sx={{paddingLeft:0, paddingRight:0}}
                        >
                            view phrase in text
                        </Button> */}
                    </Stack>
                    <Stack
                        direction="row"
                        spacing={2}
                        alignItems="center"
                        className='noSnippetClick'
                    >
                        {(quick_question_answers[index] !== undefined) && (quick_question_answers[index] === "yes") ?
                            <Button variant="yesButton" sx={{paddingLeft:1, paddingRight:1, bgcolor: '#b4e3b6', 
                                '&:hover' : {
                                    bgcolor: '#b4e3b6',
                                }}}
                                startIcon={<CheckCircleIcon className='noSnippetClick'/>}
                                onClick={handleClickYes}
                                className='noSnippetClick'
                            >
                                Yes
                            </Button>
                        :
                            <Button variant="yesButton" sx={{paddingLeft:1, paddingRight:1}}
                                startIcon={<CheckCircleIcon className='noSnippetClick'/>}
                                onClick={handleClickYes}
                                className='noSnippetClick'
                            >
                                Yes
                            </Button>
                        }
                        {(quick_question_answers[index] !== undefined) && (quick_question_answers[index] === "no") ?
                            <Button variant="noButton" sx={{paddingLeft:1, paddingRight:1, bgcolor: '#edaaaa', 
                                '&:hover' : {
                                    bgcolor: '#edaaaa',
                                }}}
                                startIcon={<CancelIcon className='noSnippetClick'/>}
                                onClick={handleClickNo}
                                className='noSnippetClick'
                            >
                                No
                            </Button>
                        :
                            <Button variant="noButton" sx={{paddingLeft:1, paddingRight:1}}
                                startIcon={<CancelIcon className='noSnippetClick'/>}
                                onClick={handleClickNo}
                                className='noSnippetClick'
                            >
                                No
                            </Button>
                        }
                        {(quick_question_answers[index] !== undefined) && (quick_question_answers[index] === "unsure") ?
                            <Button
                                sx={{paddingLeft:1, paddingRight:1, bgcolor: '#cfe4f9', 
                                '&:hover' : {
                                    bgcolor: '#cfe4f9',
                                }}}
                                startIcon={<HelpIcon className='noSnippetClick'/>}
                                onClick={handleClickUnsure}
                                className='noSnippetClick'
                            >
                                Unsure
                            </Button>
                        :
                            <Button
                                sx={{paddingLeft:1, paddingRight:1}}
                                startIcon={<HelpIcon className='noSnippetClick'/>}
                                onClick={handleClickUnsure}
                                className='noSnippetClick'
                            >
                                Unsure
                            </Button>
                        } 
                    </Stack>
                    </Stack>
                }
                </div>
                </Slide>
                {/* The divider and page navigator */}
                <Divider sx={{borderBottomWidth: '2px', paddingTop:1}} className='noSnippetClick'/>
                <Stack
                    direction="row"
                    sx={{paddingTop:1}}
                    justifyContent="flex-end"
                    alignItems="center"
                    spacing={1}
                    className='noSnippetClick'
                >
                    { (index < props.rec["quick_questions"].length) &&
                        <Typography className='noSnippetClick'>
                            {index+1} of {props.rec["quick_questions"].length}
                        </Typography>
                    }

                    <IconButton aria-label="previous" size="small"
                        disabled={index === 0}
                        sx={{color:theme.palette.primary.main}}
                        onClick={handleClickPrev}
                        className='noSnippetClick'
                    >
                        <ChevronLeftIcon className='noSnippetClick'/>
                    </IconButton>

                    <IconButton aria-label="next" size="small" 
                        sx={{color:theme.palette.primary.main}}
                        disabled={index === props.rec["quick_questions"].length}
                        onClick={handleClickNext}
                        className='noSnippetClick'
                    >
                        <ChevronRightIcon className='noSnippetClick'/>
                    </IconButton>

                </Stack>
            </AccordionDetails>
        </Accordion>
        </ThemeProvider>
    )
}

export default QuickQuestions;